
.tippy-filter-box {
    & .tippy-box {
        width : 500px ; 
        max-width: inherit!important;
        -webkit-box-shadow: 5px 5px 4px -2px rgba(0,0,0,0.18); 
        box-shadow: 5px 5px 4px -2px rgba(0,0,0,0.18);
        border-radius: 10px;
        
        & .tippy-content {
            background-color: white!important;
            border-radius: 10px;
        }
    }

    

    & .filter-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        &__button {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            transition : all 110ms ; 
            border: 1px solid transparent;
            cursor: pointer;
            position: relative;

            &--active {
                background-color: #bcdbfb!important;
                border: 1px solid #007aff;
            }

            &--length {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 100;
                background-color: #007aff!important;
                border: 1px solid #007aff;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 11px;
                top: -7.5px;
                right: -7.5px;
                color: white;
            }

            &:hover {
                border: 1px solid rgba(186, 186, 186, 0.808);
                background-color: #f5f7f9;
                &::after {
                    content : "Filtrer" ; 
                    z-index: 1100;
                    display: flex;
                    position :absolute ; 
                    border-radius: 10px;
                    border: 1px solid rgba(186, 186, 186, 0.808);
                    font-size: 13px;
                    top: calc(100% + 6px);
                    //left: calc(10% + 10px);
                    padding: 5px 10px;
                    background-color: white;
                    -webkit-box-shadow: 5px 5px 4px -2px rgba(0,0,0,0.18); 
                    box-shadow: 5px 5px 4px -2px rgba(0,0,0,0.18);
                }

            }



            & .tc-icons {
                width: 70%;
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;

                & svg {
                    width: 100%;
                    height: 100%;
                }
            }



        }


        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & label {
                color: #363636;
            }


        }

        &--title {
            color : $color_1 ; 
            height: 40px;
            font-size: 17px;
            font-weight: bold;
            margin-bottom : 20px
        }

        & .restart-button {
            position: absolute;
            top: 10px;
            right: 10px;
            height: 20px ; 
            display : flex ; 
            cursor: pointer;
            &:hover::after {
                content : "Réinitialiser" ; 
                z-index: 1100;
                display: flex;
                position :absolute ; 
                border-radius: 10px;
                border: 1px solid rgba(186, 186, 186, 0.808);
                font-size: 11px;
                top: calc(100% + 6px);
                //left: calc(10% + 10px);
                padding: 5px 10px;
                background-color: white;
                color: #363636;
                -webkit-box-shadow: 5px 5px 4px -2px rgba(0,0,0,0.18); 
                box-shadow: 5px 5px 4px -2px rgba(0,0,0,0.18);
            }
        }

        & #analytic-code, #status, #supplier, #payment_mode, #date {
            margin: 5px 0px;
        }


        & .due_date {

            &--container {
                display: flex;
                align-items: center;
            }
            & .tc-datepicker-wrapper input {
                width: 80px!important;
            }

            & p {
                font-size: 12px;
                margin: 0px 5px;
            }
        }

        $search-width : 220px ; 

        & #analytic-code, #date {
            & input {
                border-radius: 10px;
                height: 28px;
                padding: 0px 10px;
                border: 1px solid rgb(209, 209, 209);
                text-align: center;
                font-size: 13px;

                &:focus {
                    outline: none;
                    border: 1px solid $color_3;
                }
            }
        }

        & #analytic-code input {
            width: calc(80px - 10px);
        }

        & #date {
            position: relative;
            & input {
                width: calc(150px - 10px);
            }

            & .tc-datepicker__tab-loop {
                position: absolute;
                top: 100%;
                z-index: 1200;
            }
        }

        //

        & #status {
            & .dropdown {
                width: $search-width;
                height: 30px;
                border-radius: 10px;
                border: 1px solid rgb(209, 209, 209);
                //z-index: 1100;

                & p {
                    width: calc(100% - 50px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;

                }

                & div, p {
                    font-size: 13px!important;
                }

                &--list {
                    top: calc(100% + 5px);
                    border-radius: 10px;

                    & > div {
                        color : $color_1 ; 
                        justify-content: flex-start;
                        width: calc(100% - 40px);
                        padding: 0px 20px;
                        text-align: start;

                    }
                }
            }
        }

        & #supplier {
            #search-select {
                width : $search-width ;
                margin: 0;

                & input {
                    height: calc(30px - 2px);
                    border-radius: 10px;
                    border: 1px solid rgb(209, 209, 209);
                }

                & .list {
                    border: 1px solid rgb(209, 209, 209)!important;
                    & * {
                        color : #363636 ;
                    }
                }
            }
        }

        & #payment_mode {
            &--select {
                width: $search-width;
                display: flex;
                height: 30px;
                justify-content: flex-end;
                align-items: center;

                & > div {
                    width: 80px;
                    height: 25px;
                    font-size: 13px;
                    border-radius: 10px;
                    border: 1px solid rgb(209, 209, 209);
                    color : #363636 ; 
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    cursor: pointer;

                    &:nth-child(1) {
                        margin-right: 10px;
                        &:hover {
                            background-color: darken(#34C759, 10);
                            color: white;
                        }
                    }

                    &:nth-child(2) {
                        margin-right: 10px;
                        &:hover {
                            background-color: darken(#FF3B30, 10);
                            color: white;
                        }
                    }

                    &:nth-child(3) {
                        &:hover {
                            background-color: darken(#007bff, 10);
                            color: white;
                        }
                    }
                }

                & #paid-selected {
                    background-color: #34C759;
                    color: white;
                }

                & #unpaid-selected {
                    background-color: #FF3B30;
                    color: white;
                }

                & #credit-note-selected {
                    background-color: #007bff;
                    color: white;
                }
            }
        }
    }
    
}