.settings {
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 3001;


    & .stepsText {
        //left: 120px;
        position: absolute!important;
    }


    & > .basepopup {

        &__container {
            width: calc(100%);
            height : calc(100%); 
            max-height: inherit!important;

            & > .content {
                flex: 1 1;
                padding: 0!important;
                position: relative;
                width: 100%;
                height: calc(100% - 30px);
            }
        }
    }

    &--header {
        padding: 10px 5px;
        width: calc(60px - 10px);
        height: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: $background_app ;
        transition : width 110ms ;  
        //align-items: center;
        z-index: 2500;

        &--icon {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            & svg {
                width: 30px;
                transition : all 110ms
            }

            & p {
                opacity: 0;
                font-weight: 500;
                color : $color_2 ; 
                margin: 5px 0px;
            }
        }

        &--item {
            width: calc(100%);
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            & .tc-icons {
                width: 25px;
                height: 25px;
                display: flex;
                & svg {
                    width : 80% ; 
                }
            }

            & p {
                width: 0;
                color: $color_1!important ; 
                font-size: 13px;
                overflow: hidden;
                font-weight: 400;
                opacity: 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-align: justify;
            }
        }

        & .active {
            background: $color_3;
            border-radius: 10px;

            & p {
                color: white!important;
            }
            & .tc-icons {
                & svg path {
                    fill: white!important;
                }
            }
        }

        &:hover {
            width: 200px;
            box-shadow: 3px 1px 12px 0px #1414141a;
            z-index: 3000;

            & .settings--header--icon {
    
                & svg {
                    width: 60px;
                }
    
                & p {
                    opacity: 1;
                    margin: 10px 0px;
                }
            }

    
            & .settings--header--item {
                overflow: hidden;
                justify-content: space-between;

                & .tc-icons {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10px;

                }

                & p {

                    width: 100px;
                    height: 100%;
                    opacity: 1;
                    flex: 1 1;
                    padding-left : 10px ; 
                    position : relative ; 

                    & span {
                        position: absolute;
                        width: 200px;
                        left: 10px;
                    }
                }
                
            }

    
        }
    }

    &--container {
        height: 100%;
        margin-left: 60px;
        width: calc(100% - 60px);

        & .detail_user .content {
            background-color: transparent;
        }
    }
}