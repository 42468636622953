@keyframes flash {
    0% { transform: scale(1); }
    50% { transform: scale(1.5); }
    100% { transform: scale(1); }
  }

.access {
    width: 100%;
    height: calc(100% - 50px);
    flex : 1 1 ; 
    display: flex;
    z-index: 2000;
    margin-top: 50px;

    & .stepsText {
        position: fixed;
    }

    //PARENT
    & .listing {
        padding-top : 10px; 
        height: calc(100% - 10px);
        flex : 1 1 ; 
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    //CHILDRENS
    & .toolbar, .thead, .tbody {
        width: calc(100% - 40px);
        margin : 0px 20px
    }

    & .toolbar {
        height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        
        & button {
            height: 70%;
            border-radius: 10px;
            padding: 0px 10px;
            border: none;
            font-family: $font ; 
            font-weight: 600;
            cursor: pointer;
            margin: 0px 10px;
        }

        & .add {
            background : $color_gradient ; 
            color: whitesmoke;
            font-size: 13px;
            letter-spacing: 0.5px;
        }

        & .delete {
            background: rgb(163,0,0);
            background: linear-gradient(45deg, rgba(163,0,0,1) 0%, rgba(240,1,1,1) 100%);
            color: white;
        }
    }

    & .thead, .tbody > div > div {
        display: flex;
        align-items: center;
        height: 70px;

        & .user_selected {
            background: $color_gradient;
        }

        & .tc-icons {
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        & > div {
            flex: 1 1;
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;


            & p::first-letter {
                text-transform: uppercase!important;
            }

            &:first-child {
                //background-color: red;
                height: 100%;
                flex: inherit;
                width: 20px;
                height: 20px;
                margin: 0px 10px;
                border-radius: 50%;
                border: 0.5px solid $color_1;
                opacity: inherit;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }


        }
    }

    & .thead > div {
        font-style: italic;
        font-weight: 600;
        color : $color_2 ; 
        opacity: 0.7;
    }

    & .tbody {
        height : calc(100% - 60px) ; 
        display: flex;
        flex-direction: column;
        overflow-y: scroll;

        & > div {

            & > div {
                cursor: pointer;
                height: 50px;
                &:nth-child(2n + 1) {
                    background-color: #51ba6929;
                }

                & >  p {
                    margin: 0;
                    transition : all 110ms ; 
                }

                & .detail_access {
                    position: relative;

                    & p {
                        cursor: pointer;
                    }

                    & p:hover + div {
                        display: flex;
                        
                    }   

                    & > div {
                        position: absolute;
                        width: auto;
                        height: auto;
                        z-index: 1000;
                        top: 110%;
                        background-color: white;
                        border-radius: 5px;
                        width: 300px;
                        display: none;
                        z-index : 2000; 
                        flex-direction: column;
                        -webkit-box-shadow: 0px 7px 9px -2px rgba(0,0,0,0.36); 
                        box-shadow: 0px 7px 9px -2px rgba(0, 0, 0, 0.11);

                        & > div {
                            width: auto;
                            height: 60px;
                            display: flex;
                            align-items: center;
                            padding: 0px 10px;

                            & .active {
                                background-color: green!important;
                                & > div {
                                    background-color: rgb(0, 233, 0)!important;
                                }
                            }

                            & .circle {
                                position: relative;
                                width: 10px;
                                height: 10px;
                                margin: 0px 10px;
                                border-radius: 50%;
                                background-color: rgb(188, 2, 2);

                                & > div {
                                    position: absolute;
                                    border-radius: 50%;
                                    opacity: 0.5;
                                    background-color: rgba(255, 0, 0, 0.627);
                                    width: 10px;
                                    height: 10px;
                                    animation : flash infinite 1s

                                }
                            }

                            & .navtitle {
                                position: relative;
                                & p {
                                    margin: 0;

                                    &:last-child {
                                        position: absolute;
                                        font-style: italic;
                                        font-size: 12px;
                                        margin-top: 2px;
                                    }
                                }

                            }

                        }
                        //display: none;
                    }
                }

                &:hover {

                    & p {
                        font-weight: 700;
                    }

                    
                    & > div > p {
                        transform: scale(1.1);
                    }
                }



            }
            
        }

    }
}


//NEW USER && DETAIL USER (Manage Users)
.newuser, .detail_user {
    z-index: 2500;

    & .select {
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: center;


        & button{
            height : 35px;
            padding: 0px 10px;
            margin: 0px 10px;
            border: none;
            background-color: $color_4;
            display: inline-flex;
            //width: auto;
            border-radius: 10px;
            font-size: 13px;
            color: black;
            cursor: pointer;
            text-align: center;
            justify-content: center;
            align-items: center;
            opacity: 0.5;
            
            &:hover {
                background-color: $color_3;
                opacity: 1;

                & p {
                    color: white;
                }
            }

            & p::first-letter {
                text-transform: uppercase;
            }

        }

        & .active {
            background-color: $color_1!important;
            opacity: 1;
            & p {
                color: white;
            }

        }
    }

    & .content {
        padding : 10px 20px;
        width: 700px;
        height: 600px;
        display: flex; 
        justify-content: space-around;
        align-items: flex;
        flex-wrap: wrap;

        //BLOC 
        & > div{
            display: flex;
            flex-direction: column;
            width: 40%;
            overflow: hidden;
            border-right: 2px solid rgba(1, 54, 1, 0.129);
            //border-left: 2px solid $color_1;

            &:nth-child(2n + 1) {
                //background-color: blue;
                padding : 10px 30px;
            }

            &:nth-child(2n) {
                //background-color: orange;
                padding : 10px 10px;
                border-right: none;
            }


            //INPUTS CONTAINER
            & > div {
                width: 100%;

                & > div {
                    display: flex;
                    flex-direction: column;
                    margin-top: 10px;

                        label {
                            margin: 10px 0px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 16px;
                            font-weight: bold;
                            color : $color_1 ; 
                        }
                        
                        input {
                            @include inputStyle()
                        }
                }

            }

        }
    }

    & .main-button {
        width: 100px!important;
        margin: 10px 0px;
        border: none!important;
    }


}


//DETAIL USER
.detail_user {
    width: calc(100% - 0px);
    height: calc(100% - 40px);
    //padding: 0px 10px;
    padding-top: 30px;
    padding-bottom: 10px;
    position: relative;
    display: flex;

    & .content {
        align-items: flex-start;
    }

    & .return {
        position: absolute;
        top: 10px;
        left: 10px;
        //background-color: red;
        width: 25px;
        height: 25px;
        padding: 5px;
        cursor: pointer;
    }

    & .manage_access {
        display: flex;
        flex-direction: column;
        flex : 1 1; 
        height: calc(100% - 50px);
        padding : 0px 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-left: 2px solid rgba(1, 54, 1, 0.129);
        //align-items: center;

        & h1 {
            width: 100%;
        }

        & > span {

        }

        //LIST NAV CONTAINER
        & > div {
            width: 450px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            & > div {
                width: 200px;
                margin-top: 20px;

                &:nth-child(1) {
                    margin: 0;
                }

                &:nth-child(2) {
                    margin: 0;
                }




                & > p {
                    font-weight: bold;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                & p, div {
                    font-size: 16px;
                }

                & .submenu {
                    width: 100%;
                    @include dropdownStyle() ; 
                }
                
            }



        }


    }

    & .illustration {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 250px;
    }
}

