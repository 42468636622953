
.forgot-pwd {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafcf7;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & .mini-load svg  {
        width: 40px;
    }

    &--container {
        width: 390px;
        min-height: 350px;
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        //height: calc(300px - 40px);
        padding: 20px 0px;
        background-color: #FEFEFE;
        backdrop-filter: blur(5px);
        border-radius: 5px;
        border: 1.25px solid rgba(128, 128, 128, 0.28);
        -webkit-box-shadow: 0px 8px 5px -5px rgba(0,0,0,0.35); 
        box-shadow: 0px 8px 5px -5px rgba(0, 0, 0, 0.548);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    & .logo, .illustration {
        position: fixed;
        & svg {
            width: 100% ; 
            height: 100%;
        }
    }

    & .logo {
    top: 20px;
    left: 20px;
    height: 30px;
    }

    & .illustration {
    width: 300px;
    bottom: 20px;
    right: 20px;
    }

    

    & .return {
        width: 30px ; 
        height: 30px ; 
        @include buttonStyle("icons") ; 
        position: absolute;
        left: 10px;
        top: 10px;

        & .tc-icons {
            width: 100%;
            height: 100%;
            
            & svg {
                width: 50%;
                height: 50%;
            }
        }

        & a {
            width: 100%;
            height: 100%;


        }
    }

    & p {
        width: calc(100% - 30px);
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    & input {
        width: 200px;
        margin : 10px 0px ; 
        text-align: center;
        padding: 0px 5px!important;
        @include inputStyle()
    }

    & .main-button {
        margin-top: 10px;
    }

    &--code {
        width: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & p {
            margin: 0;
            text-align: center;
            display: flex;
            flex-direction: column;
    
            & span:last-child {
                margin: 10px 0px;
            }
        }

        & .resend {
            cursor: not-allowed;
            color : rgb(60, 60, 60) ; 
            opacity: 0.5;
            margin-top: 10px;
            font-size: 13px;

            &__active {
                cursor: pointer;
                color : $color_1 ;
                opacity: 1; 

                &:hover {
                    text-decoration: underline;
                    color : $color_3 ;
                }
            }
        }
    }

    &--pwd {
        width: calc(100% - 40px);
        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;

            & label {
                @include labelStyle() ; 
            }

        }

        & .intro {
            margin-bottom: 20px;
            & h1 {
                text-align: center;
            }

            & p {
                text-align: justify;
            }
        }

        & .pwd_1, .pwd_2 {

            & .input {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                 & input {
                    width: calc(100% - 45px);
                    padding-left: 10px!important;
                    padding-right: 35px!important;
                 }

                & .visible {
                    position: absolute;
                    right : 10px ; 
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    height: 100%;
                    width: 20px;
                }
            }
        }


        & .pwd_1 {
            margin-bottom: 20px;

            & .checking-format {
                width: 90%;
                overflow: hidden;
                & > p {
                    justify-content: flex-start!important;
                    margin: 0px;
                }

                & > div {

                    & > div {
                        display: flex;
                        align-items: center;

                        & .tc-icons {
                            width: 12.5px;
                        }

                        & p {
                            display: flex;
                            flex: 1 1;
                            margin: 0px 5px;
                            justify-content: flex-start;
                            color: rgb(199, 1, 1);
                        }
                    }

                    & .active {
                        
                        & .tc-icons svg path {
                            fill : green!important
                        }

                        & p {
                            color: green;
                        }
                    }




                }
            }
        }
    }


}