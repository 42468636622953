
.payment-detail {


    & .basepopup__container, .basepopup__container {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        max-height: inherit;
        padding: 0;
    }

    & .content {
        width: 100%;
        height: calc(100% - 30px);
        padding: 0;
        overflow: hidden;
    }

    &__header {
        width: calc(100% - 20px);
        height: 50px;
        display: flex;
        padding: 0px 10px;
        align-items: center;

        & h1 {

        }

        & .general-statuses {
            display: flex;
            flex: 1 1;
            justify-content: flex-end;
            & > div {
                margin : 0px 5px ; 
                padding: 5px 10px;
                border-radius: 10px;
                border: 1px solid rgb(195, 195, 195);
            }

            &--comment {
                
            }

            &--payment {

            }
        }

    }

    &__body {
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: row;

        & > div {

        }

        &--invoice {
            width: calc(100% - 400px);
            height: 100%;
            background-color: rgba(0, 33, 4, 0.255) ; 

            & iframe {
                width: 100%;
                height: 100%;
                border: none;
            }
        }

        &--payments {
            width: calc(400px - 20px);
            height: calc(100% - 20px);
            padding: 10px 10px;
            position : relative ; 

            & .button-mode {
                position: absolute;
                top: px;
                right: 10px;
                background-color: $color_1;
                width: 30px;
                height: 30px;
                z-index: 1000;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 50%;
                &:hover {
                    background-color: $color_2;
                }

                &:hover .arrows-repeat {
                    transform: scale(1);
                }

                &:hover .comments, &:hover .money-bill {
                    display: none;
                }

                & .arrows-repeat {
                    position: absolute;
                    transform: scale(0);
                    transition: transform 0.2s ease-in-out;
                }

                & .tc-icons {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & svg {
                        width: 100%;
                        height: 100%;
                    }
                }


                
            }

            & h1 {
                margin: 0;
                height: 30px ; 
                text-align: center;

            }

            & > div {
                width: 100%;
                height: calc(100% - 30px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;


                & .status-log {
                    width: calc(100%);
                    height: calc(100% - 200px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    & .mini-load .tc-icons {
                        width: 30px;
                        height: 30px;
                        min-width: inherit;
                        min-height: inherit;

                        & svg path {
                            fill  : $color_4 ; 
                        }
                    }

                    &::after, &::before {
                        content: "";
                        display: flex;
                        position: absolute;
                        width: 100%;
                        height: 40px;
                        z-index: 1000;
                    }

                    &::after {
                        bottom : 0 ; 
                        background: rgb(254,254,254);
                        background: linear-gradient(0deg, rgba(254,254,254,1) 2%, rgba(254, 254, 254, 0) 80%);
                    }

                    &::before {
                        top : 0 ; 
                        background: linear-gradient(180deg, rgba(254,254,254,1) 2%, rgba(254, 254, 254, 0) 80%);
                    }

                    & .comments-history {
                        width: 100%;
                        height: 100%;
                        overflow-y: scroll;
                        position : relative ; 
                        



                        & > div {
                            padding: 50px 0px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                        &__item {
                            width: calc(100% - 20px );
                            padding: 15px 0px;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            & * {
                                color: #393939;
                            }

                            &:after {
                                content : "" ; 
                                position: absolute;
                                width: 80%;
                                height: 1px;
                                background-color: rgba(0, 128, 0, 0.168);
                                bottom : 0; 
                            }

                            &--status {
                                width: calc(100% - 50px);
                                padding-right: 50px;
                                display: flex;
                                align-items: center;
                                position: relative;

                                & .buttons-actions {
                                    position: absolute;
                                    right: 20px;
                                    & > div {
                                        width: 20px;
                                        height: 20px;
                                        position: relative;
                                        display: flex;
                                        justify-content: center ; 
                                        align-items: center;
                                        cursor: pointer;

                                        & .tc-icons {
                                            width: 90%;
                                            height: 90%;

                                            & svg {
                                                width: 100%;
                                                height: 100%;
                                            }
                                        }

                                        &:hover p {
                                            display: flex;
                                        }

                                        & p {
                                            position: absolute;
                                            display: none;
                                            font-size: 11px;
                                            top: -35px;
                                            margin: 0;
                                            padding: 5px 5px;
                                            background-color: rgb(167, 1, 1);
                                            background-color: #FEFEFE;
                                            border: 1px solid rgb(184, 184, 184);
                                            border-radius: 10px;
                                            -webkit-box-shadow: 4px 4px 14px -4px rgba(26, 27, 26, 0.22);  
                                            box-shadow: 4px 4px 14px -4px rgba(26, 27, 26, 0.259);
                                        }
                                    }
                                }
                            }

                            &--comment {
                                width: calc(100% - 20px - 10px);
                                margin-top: 10px;
                                padding: 5px 10px;
                                bottom: calc(100% + 5px);
                                background-color: white;
                                border: 1px solid rgb(206, 206, 206);
                                border-radius: 10px;
                                font-size: 14px;
                            }

                            &--date {
                                font-size: 12px;
                                font-style: italic;
                                margin: 0;
                                text-align: end;
                                margin-top: 5px;
                                width: 100%;
                            }
                        }
                    }
                }

                & .no-data {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    & svg {
                        width: 200px;
                        max-height: 80%;
                    }
                }

                & .payments {
                    &-history {
                        width: 100%;
                        height: calc(100% - 70px);
                        position : relative ; 
                        


                        & .tc-table {
                            margin-top: 10px;
                            &__footer, & .tc-table__header--search-box {
                                display: none;
                            }

                            &__thead {
                                border-top-left-radius: 10px ; 
                                border-top-right-radius: 10px;
                            }

                            &__tbody {
                                height: calc(100% - 100px)!important;
                            }

                            &__header--custom {
                                & button {
                                    padding: 5px 10px;
                                    margin: 0px 5px;
                                    border: none;
                                    border-radius: 40px;
                                    font-size: 14px;
                                    cursor: pointer;
                                }
                            }
                        }

                    }

                    &-entry {
                        width: 80%;
                        height: 70px; 
                        position: relative;
                        //background: red;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        & > div {
                            display: flex;
                            flex-direction: column;
                        }

                        & .tc-datepicker-wrapper {
                            width: 100px;
                            height: 30px;

                            & input {
                                text-align: center; 
                                padding-left: 0;
                                border: none;
                                background-color: $color_1;
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;
                                color: white;

                                &:focus {
                                    outline: none;
                                }
                            }


                            & * {
                                width: 100px;
                                height:calc(100%)!important;
                            }
                        }

                        & .input-amount {
                            width: 80%;
                            height: calc(100% - 30px - 5px);
                            padding-left: 10px;
                            border: 2px solid $color_1 ;
                            border-radius: 10px;
                            font-size: 14px;
                            font-weight: bold;
                            position: relative;
                            z-index: 1300;

                            &:focus {
                                outline : none  ; 
                            }

                            &:after {
                                content: "€";
                                display: flex;
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                font-size: 18px;
                                font-weight: bold;
                            
                            }
                        }

                        & > p {
                            position: absolute;
                            z-index: 1400;
                            bottom: 0;
                            /* margin: 0; */
                            height: 40px;
                            bottom: 0;
                            display: flex;
                            align-items: center;
                            margin: 0;
                            right: 35px;
                            font-size: 14px;
                            font-weight: bold;
                        }

                        & .button-send {
                            right: -12.5px;
                            bottom: 0px;
                            width: 30px;
                            height: 40px;
                            padding-left: 50px;
                            padding-right: 5px;
                            border-radius: 10px;
                        }
                    }
                }

                & .bubble {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    margin-left: 10px;
                    border-radius: 50%;
                    border: 1px solid rgb(206, 206, 206);
                }

                & .title {
                    display: flex;
                    margin: 0;
                    flex: 1 1;
                }

                & .status-entry {
                    width: 100%;
                    height: 200px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    justify-content: center;

                    &:before {
                        position: absolute;
                        content : "" ; 
                        background-color: rgb(211, 211, 211);
                    }

                    &__chooseStatus {
                        height: 30px;
                        display: flex;
                        position: relative;

                        &:hover:after {
                            display: flex;
                        }

                        &:after {
                            content : "" ; 
                            position: absolute;
                            display: none;
                            height: 20px;
                            width: 100%;
                            bottom: calc(100% - 5px);
                            z-index: 1400;
                        }
                        



                        & .listing {
                            width: calc(100% - 20px);
                            position: absolute;
                            padding: 5px 10px;
                            bottom: calc(100% + 5px);
                            background-color: white;
                            border: 1px solid rgb(206, 206, 206);
                            border-radius: 10px;
                            z-index: 1100;
                            -webkit-box-shadow: -1px -2px 5px 0px rgba(0,0,0,0.18); 
                            box-shadow: -1px -2px 5px 0px rgba(0,0,0,0.18);

                            & > div {
                                width: 100%;
                                display: flex;
                                position: relative;
                                margin: 5px 0px;
                                cursor: pointer;
                            }

                            & .bubble {
                                margin-left: 0px!important;
                            }
                        }
                    }

                    &__comment {
                        height: calc(100% - 40px);
                        border: 1px solid rgb(186, 186, 186);
                        border-radius: 10px;
                        display: flex;
                        position: relative;
                        border: 2px solid $color_2;
                        
                        & textarea {
                            width: calc(100% - 20px);
                            height: calc(100% - 20px);
                            margin: 10px 10px 10px 10px;
                            border: none;
                            resize: none;
                            border-radius: 10px;
                            font-family: $font;

                            &::placeholder {
                                font-style: italic;
                            }

                            &:focus {
                                outline: none;
                            }
                        }


                    }   


                }

                & .button-send {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    background-color: $color_1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    bottom: 5px;
                    right: 5px;
                    cursor: pointer;

                    &:hover {
                        background-color: $color_2;
                    }

                    & .tc-icons {
                        width: 50%;
                        height: 100%; 
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        & svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    & .paper-plane-top {
                        & svg path {
                            &:first-child {
                                opacity: 1!important;
                            }
                            &:last-child {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}