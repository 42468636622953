

.payments {
    & .tc-table {
        & .comment-status {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > p {
                margin: 0;
                text-align: center;
            }
        }

        &__footer--infos {
            position: fixed;
            right: 0px;
        }

        & .tc-payment-status {
            width: 75px;
            padding: 5px 5px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            &--paid {
                color: green;
                background-color: rgba(98, 255, 64, 0.16);
            }

            &--to_pay {
                color: red;
                background-color: rgba(255, 0, 0, 0.08);
            }

            &--credit-note {

                color: #007bff;
                background-color: rgba(0, 123, 255, 0.08);
            }
        }

        &__tbody {
            & .tc_payments_mode, .tc_comment {
                cursor: pointer;
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
                align-items: center;
        
                & p {
                    width:230px;
                    margin: 0;
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
        
                }
            }

            &--row--column .doc_number {
                height: 100%;
                display: flex; 
                justify-content: center;
                align-items: center;
                text-align: center;
                margin: 0;
                position: relative;

                &--green {
                    background-color: $color_1;
                    color: white!important;
                    &:hover::after {
                        display: flex;
                    }


                    &::after {
                        content : "Mode de paiement auto" ; 
                        position: absolute;
                        background-color: #fff;
                        border: .5px solid #a9a9a9;
                        border-radius: 10px;
                        font-size: 13px;
                        padding: 5px 10px;
                        top : calc(100%) ; 
                        color : black ; 
                        z-index: 1000;
                        display: none;
                    }
                }


            }
        }
    }

}

.payments {
    overflow-x: scroll!important;
    justify-content: flex-start;

    & .invoice-detail {

        & .tc-table {

            &__header {
                position: absolute;
                left: 0;
                width: 100vw;

                &--custom {

                    & .export {
                        position: relative;

                        &:hover .export--menu {
                            display: flex;

                        }
                        &--menu {
                            display: none;
                            position: absolute;
                            left: 0px;
                            top: 30px;
                            z-index: 1000;
                            width: 250px;
                            background-color: white;
                            border: 1px solid #8a898978;
                            -webkit-box-shadow: 5px 5px 7px -1px rgba(0,0,0,0.27); 
                            box-shadow: 5px 5px 7px -1px rgba(0,0,0,0.27);
                            //display: flex;
                            flex-direction: column;
                            padding: 5px 10px;
                            border-radius: 10px;

                            & > div {
                                display: flex;
                                justify-content: flex-start;
                                text-align: start;
                                margin: 5px 0px;
                                color: $color_1;

                                &:hover {
                                    color: $color_3;
                                    font-weight: 600;
                                }
                            }

                            &::before {
                                content : "" ; 
                                position: absolute;
                                width: 100%;
                                top: -10px;
                                left: 0px;
                                height: 10px;
                                //background-color: red;
                            }
                        }
                    }
                }
            }
            &__thead {
                margin-top: 50px;


                &--column {
                    font-size: 13px;
                }
            }
        }
    }

    & .stepsText {
        position: fixed;
    }

    & .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & svg {
            width: 400px;
            margin-bottom: 20px;
        }

    }

    & .table {
        width: 100%;
        height: 100%;
    }


}