
.suppliers-manager {
    width: 100%;
    height: 100%;
    user-select: none;
    z-index : 2500 ; 

    & .content {
        width: 500px;
        display: flex; 
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
       //overflow: hidden;

        & .name, .address {
            & input, .dropdown {
                width : 100%
            }
            
            & input {
                @include inputStyle() ; 
            }
        }

        & .other_infos {
            width: 100%; 
            height: 300px;
            display: flex;
            flex-direction: row;

            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }



        }

        & input:focus {
            outline: none;
        }

        //BLOC 
        & > div{
            display: flex;
            flex-direction: column;
            align-items: center;
            //width: calc(100% - 40px);
            margin-top: 10px;
            //border-left: 2px solid $color_1;

            &:nth-child(2n + 1) {
                //background-color: blue;
                padding : 10px 30px;
            }


            label {
                margin: 10px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: bold;
                color : $color_1 ; 
            }




        }
    }


    & .modals {
        display: flex;
        width: 100%;
        display: flex;
        flex-direction: column;


        & .buttons {
            display: flex;
            height: 40px;
            & .active {
                opacity: 1;

                & p {
                    font-weight: bold;
                    color : $color_3!important; 
                }

                

                & svg path {
                    fill : $color_3!important
                }
            }

            & > div {
                display: flex;
                align-items: center;
                margin: 0px 10px;
                opacity: 0.4;
                cursor: pointer;

                & .tc-icons {
                    width: 20px;
                    margin: 0px 5px;
                }

                &:first-child .tc-icons {
                    margin-left: 0;
                }

                & p {
                    margin: 0;
                    font-size: 15px;
                }


            }
        }

        & .selected-modal {
            width: 100%;
            height: calc(100% - 50px);
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            border-radius: 5px;
            transition: all height 1s;


            &__container {
                position: relative;
                width: 100%;
                height: 100%;
                min-height: 300px;
                //background-color: #ffffff;
                //border: 1px solid rgba(128, 128, 128, 0.217);
                transition: all 0.4s;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                //overflow: hidden;

                & .resize {
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    top: -10px;
                    right: -10px;
                    padding: 0!important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none ; 
                    @include buttonStyle("icons") ;
                    z-index: 4000; 

                    & svg {
                        width: 50%;
                        height: 50%;
                    }
                }


                
            }

        }

        & .tableMagic {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }

        .tableMagicController {
            background-color: #fefefe;
        }

        & .fullscreen {
            margin: 0px;
            width: calc(100%)!important;
            height: 100%;
            z-index: 4000;
            position: fixed;


            & .selected-modal__background {
                width: 100%;
                height: 100%;
                backdrop-filter: blur(10px);
                top: 0;
                left: 0;
                position: absolute;
                background-color: rgba(74, 74, 74, 0.328);

            }

            & .selected-modal__container {
                z-index: 2000;
                width: calc(100% - 40px);
                height: calc(100% - 40px) ;
                min-height : 300px

   
            }
        }




    }

    & .address {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & input {
            margin-bottom: 10px;
        }

        & #zip_code {
            width: 100px!important;

            & input { text-align : center}
        }
        

    }

    & .contact {
        width : calc(100% - 40px);
        height: 300px;
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        & .name input {
            text-align: center;
        }

        & .insert, .thead, .tbody {
            width: calc(100% - 40px);
            display: flex;
            border-radius: 5px;
            position: relative;

            & .add_button {
                width: 25px;
                height: 25px;
                border-radius: 50%!important;
                display: flex;
                justify-content: center;
                position: absolute;
                right : -35px; 
                top: 50%;
                transform: translateY(-50%);
                align-items: center;
                background-color: $color_4;
                border-radius: 5px 0px 0px 5px;
                cursor: pointer;

                & .tc-icons {
                    width: 90%;
                    height: 90%;
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    & svg {
                        max-width: 50%;
                        max-height: 50%;
                    }

                }

                &:hover {
                    background-color: $color_3;
                }
            }

            & #label {
                width : calc(30% - 20px) ; 
                background-color: white;

            }

            & #type {
                width : 35%;

            }

            & #value {
                width : calc(35% - 20px) ; 
                background-color: white;

            }

        }

        & .insert {
            height: 40px;
            background-color: white;
            border: 1px solid rgba(1, 54, 1, 0.129) ;
            z-index: 2000;

            & #type {
                border-right: 1px solid rgba(1, 54, 1, 0.129);
                border-left: 1px solid rgba(1, 54, 1, 0.129);


            }

            & .dropdown {
                border : none ; 

                &--list {
                    top: 40px!important;
                }
            }

        }

        & .thead {
            z-index: 1000;
            width: calc(100% - 40px);
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > div {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 600;
                font-style: italic;
            }
        }

        & .thead #label, .thead #value {
            padding: 0px 10px;
            border: inherit!important;
        }

        & input {
            border: none!important;
        }

        & input {
            width : calc(100% - 20px) ; 
            padding: 0px 10px;
            font-size: 13px;
        }

        & .tbody {
            width: calc(100% - 40px);
            height: calc(100% - 90px);
            padding: 0px 30px;
            z-index: 1000;
            display: flex;
            flex-direction: column;
            overflow-x: visible;
            overflow-y: scroll;

            & > div {
                //padding-bottom : 10px;
                & > div {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    position : relative ;
                    font-size: 12px;
                    border: none!important;

                    & > div {

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
    
                    & > div:nth-child(1), div:nth-child(3) {
                        display: flex;
                        padding: 0px 10px;
                    }
                }
            }

            & .nodata {
                width : 100% ; 
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 300;
                    font-style: italic;
                }

                & svg {
                    width: 150px;
                }
            }



            & .tc-icons {
                width: 10px;
            }

            & .delete_button {
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 50%!important;
                display: flex;
                justify-content: center;
                position: absolute;
                left : -25px; 
                top: 50%;
                transform: translateY(-50%);
                align-items: center;
                background-color: rgb(236, 1, 1);
                border-radius: 5px 0px 0px 5px;
                cursor: pointer;

                & .tc-icons {
                    width: 90%;
                    height: 90%;
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    & svg {
                        max-width: 50%;
                        max-height: 50%;
                    }

                }

                &:hover {
                    background-color: rgb(200, 0, 0);
                }
            }
        }

    }

    & .payment {
        width : 100%; 
        min-height : 200px ; 
        display: flex;
        flex-direction: column;
        align-items: center;


        &_choice {
            z-index: 1000;

            & .dropdown--list {
                max-height: 200px;
                overflow-y: scroll;

            }
        }

        & .payment_info {

            margin-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
            margin-bottom: 20px;

            & label {
                @include labelStyle(); 
            }

            & input {
                @include inputStyle(); 
            }

            & .bank_code input {
                width: 100px;
            }

            & .account_number {
                margin-right: 10px;
                
            }

            & .codes {
                display: flex;
                justify-content : center ; 
                margin-bottom: 10px;
                //width: 100%;
            }
        }

        & .payment_deadline {
            display: flex;
            flex-direction: column;
            z-index : 1500 ; 

        }

        & .dropdown {
            width : 200px ; 
        }

        & .nomodule {
            display: flex;
            flex : 1 1 auto ;
            width: 100%;
            justify-content: center;
            align-items: center;


            & svg {
                height: 200px;
            }
        }
    }


    & .select {
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: center;


        & button{
            height : 35px;
            padding: 0px 10px;
            margin: 0px 10px;
            border: none;
            background-color: $color_4;
            display: inline-flex;
            //width: auto;
            border-radius: 10px;
            font-size: 13px;
            color: black;
            cursor: pointer;
            text-align: center;
            justify-content: center;
            align-items: center;
            opacity: 0.5;
            
            &:hover {
                background-color: $color_3;
                opacity: 1;

                & p {
                    color: white;
                }
            }

            & p::first-letter {
                text-transform: uppercase;
            }

        }

        & .active {
            background-color: $color_1!important;
            opacity: 1;
            & p {
                color: white;
            }

        }
    }

    & .buttonPrincipal {
        width: 100px!important;
        margin: 10px 0px;
        //margin-top: 30px!important;
    }
}