.planning {
    width: 100% ; 
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
        height: 50px;
        width: 100%;
    }

    &__container {
        width: 100%;
        height: calc(100% - 50px);

        &--calendar {
            width: calc(100% - 400px);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .rbc-calendar {

                & .rbc-time-view {
                    & * {
                        border-color: rgba(169, 169, 169, 0.342)!important;
                    }
                }

                & .rbc-time-header.rbc-overflowing {
                    
                    background-color: white;
                    border-radius: 10px;
                }

                & .rbc-time-content {

                    & .rbc-time-gutter {
                        & .rbc-timeslot-group {
                            background-color: green;
                            color: white;

                        }
                    }
    
                    & .rbc-day-slot {
                        background-color: rgb(243, 255, 242);

                    }
                }





            }
        }

    }
}