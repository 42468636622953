
.amendments-detail {
    z-index: 2500;
    & .basepopup__container {
        max-height: inherit;
        width: 95%;
        height: 95%;
    }

    & .content {
        width: 100%;
        padding: 0;
        height: calc(100% - 30px);
        position: relative;
        overflow: hidden;
    }
}