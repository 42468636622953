

.new-affair {

    & .stepsText {
        position: fixed;
    }

    &--processing {
        width: 100%;
        height: 100%;
        position: relative;

        & .tc-table__thead {
            background: $color_3;
        }
        
        & .tc-table__header--custom > div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &--completion {
        width: calc(100% - 40px);
        height: calc(100% - 20px);
        border-radius: 10px;
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

        & .return {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 20px;
            left: 10px;
            padding: 0!important;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none ; 
            @include buttonStyle("icons") ;

            & svg {
                width: 50%;
                height: 50%;
            }
        }

        & .bloc {
            display: flex;
            width: 50%;
        }

        & .bloc {
            width: calc(60%);
        }

        &__container {
            background-color: white;
            width: calc(60% - 40px);
            margin: 5px 5px;
            padding: 10px 15px;
            display: flex;
            flex-direction: column;
            border: 1px solid rgb(222, 220, 220);
            border-radius: 10px;
            -webkit-box-shadow: 4px 4px 14px -4px rgba(26, 27, 26, 0.065);  
            box-shadow: 4px 4px 14px -4px rgba(26, 27, 26, 0.075);

            & h1 {
                font-size: 16px;
                height: 30px;
            }

            & > div {
                height: calc(100% - 30px);
            }
        }

        & label {
            @include labelStyle() ; 
        }

        & .identification {
            & .number-affair {
                display: flex;
                align-items: center;
                justify-content: center;

                & > div {
                    display: flex;
                }
    
                & h3, p{
                    color: black!important;
                    
                }
    
                & h3 {
                    margin-right: 5px;
                    font-weight: 300;
                }

    
                & input {
                    font-weight: 900;
                    display: inline;
                    width: auto;
                    color : white!important ; 
                    background-color: rgb(46, 46, 46);
                    padding : 5px 10px ;
                    opacity: 1;
                    border-radius: 5px;
                    border: none;
                    width: 50px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    text-align: center;
                }
    
                &:hover {
                    opacity: 0.9;
                }
    
            }

            & .choice {
                flex-direction: column;
                & > div {
                    display: flex;
                    height: 50px;
                    align-items: center;
                    justify-content: center;

        
        
                    
                    & button{
                        height : 35px;
                        padding: 0px 10px;
                        margin: 0px 10px;
                        border: none;
                        background-color: $color_4;
                        display: inline-flex;
                        //width: auto;
                        border-radius: 10px;
                        font-size: 13px;
                        color: black;
                        cursor: pointer;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        opacity: 0.5;
        
                        &:hover {
                            background-color: $color_3;
                            color: white;
                            opacity: 1;
                        }
                    }
        
                    & .active {
                        background-color: $color_1!important;
                        opacity: 1;
                        color: white;
                    }
                }
    

            }
            


        }

        & .informations-affair {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            & > div, .tc-datepicker__input-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            

            & input {
                width: 200px;
                text-align: center;
                @include inputStyle()
            }
        }

        &__intro {
            margin-top: 30px;
            width: 100%;

            & h1 {
                font-size: 20px;
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }

        & .business-manager {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .dropdown {
                width : 200px
            }
        }

        & .formMagic {
            display: flex;
            flex-direction: column;
            align-items: center;
        }


        & .input-atomix, .identification > div, .choice {
            margin-bottom: 10px;
            & > div{
               display: flex;
            }

            & label, h3 {
                margin: 10px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: bold;
                color : $color_1 ; 
            }
            
            & input {
                background-color: transparent;
                border: none;
                border-bottom: 2px solid $color_3;
                padding: 5px 0px;
                font-size: 13px;
                font-family: $font; 
                opacity : 0.8 ; 
                padding-left: 10px;
                color : $color_1 ; 
            
            
                &::placeholder {
                    color: $color_1;
                    
                }
            
                &:focus {
                    outline: none;
                    opacity: 1;
                }
            }
        }


        & .totals {

            & > div {
                width: 100%;
                height : calc(100% - 80px) ; 
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                & > div {
                    width: 75%;
                    display: flex;
                    

                    & > p {

                        font-size: 15px;
                        text-align: end;
                        display: flex;

                        &:first-child {
                            width: 40%;

                            display: flex;
                        }

                        &:last-child {
                            width: 60%;
                            justify-content: flex-end;
   
                        }
                    }


                    &:last-child {
                        margin-top: 10px;
                        border-top: 1px solid $color_1;

                        & p {
                            font-weight: bold;
                        }
                    }

                }
            }
        }

        & .input-atomix__total_ht, .input-atomix__total_tva, .input-atomix__total_ttc {
            & input {
                text-align: end;
                padding-right: 10px;
            }
        }
        

        & .submit{
            //@include buttonStyle() ; 
            margin-top: 10px;
            margin-bottom: 10px!important;
        }
    
    }
}