

.edit-inv-affairs {
    position: fixed!important;
    margin: 0!important;

    & .basepopup__container {
        max-height: inherit;
        height: calc(100% - 100px)!important;
        width: calc(100% - 20px)!important;
        & .content {
            width: 100%!important;
            background-color: #525659;
        }
    }

    $editor-height : 300px;
    &--preview {
        height: calc(100% - $editor-height);

        & iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    &--editor {
        height: $editor-height;
        -webkit-box-shadow: 0px -5px 5px -2px rgba(0,0,0,0.48); 
        box-shadow: 0px -5px 5px -2px rgba(0,0,0,0.48);
        z-index: 200;
        position: relative;
        border-radius: 5px;
        background-color: #FAFAFA;

        & .tc-table {
            &__header {
                height: 40px;
                background-color: transparent!important;

                &--search-box {
                    display: none;
                }

                & .tc-icons svg {
                    height: 20px;
                }

                & .editing > div, & .actions > div {
                    margin: 0px 5px;
                    cursor: pointer;

                    & svg {
                        cursor: pointer;
                    }
                }
            }

            &__thead {
                background-color: transparent;
                border-top: 1px solid rgba(128, 128, 128, 0.218);

                & * {
                    color : $color_1
                }
            }

            &__tbody {
                height: calc(100% - 40px)!important;

                & > div {

                    & > div {
                        &:first-child {
                            background-color: $color_1;
                            cursor: not-allowed;
                            
                            & * {
                                color: white;
                            }
                            &:hover{
                                background-color: $color_1;                            
                            }
                        }
                    }
                }

                & #search-select {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    display: flex;
                    justify-content: flex-end;

                    & input {
                        width: calc(100% - 35px);
                        border: none;
                        padding-left: 30px;
                        background-color: transparent;
                    }

                    & .list {
                        top: 100%;
                        padding-top: 0;
                        border-radius: 0px;
                        width: 100%;
                        left: -1px;
                        border-top: 0px;
                        border-color: $color_4;
                        -webkit-box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.48); 
                        box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.16);
                        z-index: 2200;

                        &--element p {
                            text-align: start;
                        }
                    }
                }

                & .current_affair {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    padding-left: 0;
                    & span {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        &:first-child{
                            width: 30px;
                            justify-content: center;
                        }

                        & svg {
                            width: 15px
                        }
                    }
                }

                & input {
                    width: 100%;
                    height: 100%;
                    border: none;
                    background-color: transparent;
                    padding-left : 10px;
                    padding-right: 20px;
                    
                    &:focus{
                        outline : none ; 
                        background-color: rgba(9, 247, 9, 0.221);
                    }
                }
            }

            &__footer {
                display: none;
            }
        }
    }
}