

.documents {
    flex-direction: column;
    position: relative;
    & .stepsText {
        position: fixed ;
    }

    &__container {
        width: 100%;
        height: 100%;
    }

    &--dragmode {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #ffffff79;
        backdrop-filter: blur(5px);
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            width: 200px;
        }
    }

    &__custom-header {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        $border-style : 1px solid rgba(102, 102, 102, .532);

        & #return, #trash {
            position: relative;
            &::after {
                position: absolute;
                background-color: #fff;
                border: .5px solid #a9a9a9;
                border-radius: 10px;
                font-size: 13px;
                color: #000;
                display: none;
                padding: 5px 5px;
            }
        }

        & #return {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding-right: 5px;
            border-right: $border-style;

            &:hover::after {
                display: flex;
            }

            &::after {
                content : "Retour" ; 
                top : calc(100%) ; 
                left: 5px;
            }
            

            

            & .tc-icons {
                width: 20px;
                height: 20px;

                & svg {
                    width: 100%;
                    height : 100% ; 
                }
            }

        }

        & #add {
            position: relative;
            padding-left: 10px;
            height: 100%;
            display: flex;
            align-items: center;

            & .tc-icons {
                width: 20px;
                height: 20px;
                cursor: pointer;

                & svg {
                    width: 100%;
                    height: 100%;
                }
            }

            &:hover #add--menu {
                display: flex;
            }


            &--menu {
                display: none;
                flex-direction: column;
                position: absolute;
                background-color: #fff;
                border: .5px solid #a9a9a9;
                border-radius: 10px;
                top: calc(100% - 10px);
                font-size: 13px;
                color: #000;
                padding: 5px 5px;
                z-index: 1000 ;
                width: auto;
                box-shadow: 5px 5px 7px -1px rgba(0,0,0,.27);

                & p {
                    white-space: nowrap;
                    padding: 5px 2.5px;
                    margin: 0px 2.5px;
                    cursor: pointer;
                    min-width: 130px;

                    &:hover {
                        color:$color_2;
                        font-weight: bold;
                    
                    }
                }
            }
        }

        & #trash {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding-left: 5px;
            padding-right: 5px;
            border-right: $border-style;

            &:hover::after {
                display: flex;
            }

            &::after {
                content : "Supprimer" ; 
                top : calc(100%) ; 
                left: 5px;
            }

            & .tc-icons {
                width: 20px;
                height: 20px;

                & svg {
                    width: 100%;
                    height : 100% ; 
                }
            }

        }
    }

    &--preview-file {
        & .basepopup__container {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            max-height: inherit;
        }

        & .content {
            width: 100%;
            height: 100%;
            padding : 0
        }
    }


    & .tc-table {
        & #name {
            width: 100%;
            height: 100%;
            display: flex;

            & .tc-icons {
                width: 40px;

                & svg {
                    height: 20px;
                }
            }

            & p {
                width: calc(100% - 40px - 5px);
                height: 100%;
                margin: 0;
                padding: 0px 5px 0px 0px;
                display: flex;
                align-items: center;

            }

        }
    }


}