

.amendments-creation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3000;


    & .content {
        transition : all 330ms ; 
        position: relative;
    }

    //basepopup amendments-creation amendments-creation--completion

    &--completion{

        & .basepopup__container {
            width: 300px;
            min-width: inherit!important;
            
            & .content {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        & .number-affair {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor:not-allowed;
    
            & > div {
                display: flex;
            }
    
            & h3, p{
                color: black!important;
                
            }
    
            & h3 {
                margin-right: 5px;
                font-weight: 300;
                font-size: 14px;
            }
    
            & p {
                font-weight: 900;
                color : white!important ; 
                background-color: rgb(46, 46, 46);
                padding : 5px 10px ;
                opacity: 1;
                border-radius: 5px;
            }
    
            &:hover {
                opacity: 0.7;
            }
    
        }
    
        & .tc-datepicker-wrapper, .input {
    
    
            label {
                margin: 10px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: bold;
                color : $color_1 ; 
            }
    
            & input {
                text-align: center ; 
                @include inputStyle() ;
            }
     
        }

        & .input {
            margin-top : 20px
        }
    
        & .main-button {
            width: 100px!important;
            margin-bottom: 10px;
            margin-top: 30px;
            border: none!important;
        }

        & .dropdown {
            width: 200px;
        }

    }

    &--import {

        & .basepopup__container {
            width: 400px;
            min-width: inherit!important;
            
            & .content {
                padding-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }

            & .import {

                width : 80% ; 
                height: 90%;
            }
        }
    }

    &--processing {

        & .basepopup__container {
            width: calc(97%);
            height: calc(97%);
            min-width: inherit!important;
            max-height: inherit!important;
            
            & .content {
                width: 100%;
                height: calc(100% - 30px);
                padding: inherit;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            & .import {

                width : 80% ; 
                height: 90%;
            }
        }
    }

}