.affair-detail {
    width: 100%;
    height: calc(100%);
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;


    & .button-return {
        z-index: 1000;
        position: absolute;
        width: 30px;
        height: 30px;
        left: 10px;
        top : 10px ; 
        padding: 0!important;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none ; 
        @include buttonStyle("icons") ;


        & svg {
            width: 50%;
            height: 50%;
        }
    }






    & > div .buttons {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
    }

    & .general-info {
        width: calc(25% - 30px);
        height: calc(100% - 30px - 50px);
        margin-top: 50px;
        position: relative;
        //VAL + PADDING + ECART


        & .edit-button {
            position: absolute;
            @include buttonStyle("icons");
            width: 25px;
            height: 25px;
            top: 10px;
            right: 10px;
    
            & div {
                display: flex;
                justify-content: center;
                align-items: center;
                & svg {
                    margin-left: 1px;
                }
            }
        }



        &__container {
            width: calc(100% - 40px);
            height : calc(100% - 13px) ; 
            overflow-y: scroll;
            padding: 5px 20px;
            background-color: #FEFEFE;
            border: 1px solid rgba(128, 128, 128, 0.217);
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            -webkit-box-shadow: 4px 4px 14px -4px #d5e0eab9;  
            box-shadow: 4px 4px 14px -4px #d5e0eab9;  



            
            & > div {
                margin: 10px 0px;
                display: flex;
                flex-direction: column;

            }
    
            & h1 {
                font-size: 18px;
            }
    
            & p {
                
                & span {
                    &:first-child {
                        text-decoration: underline;
                        opacity: 0.7;
                    }
    
                    &:last-child {
                        font-weight: bold;
                    }
                }
            }
        }



    }

    & .categorizer-manager {
        width: calc(75% - 30px);
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;

        & .resize--active {
            position: fixed;
            z-index : 3100; 
            top: 10px;
            right: 10px;
        }

        &--background {
            position: fixed;
            z-index : 2500 ; 
            width : 100% ; 
            height : 100% ; 
            top : 0 ; 
            left : 0 ; 
            background-color : rgba(5, 14, 0, 0.5) ;
        }


        &__container {
            height: calc(100% - 50px);
            background-color: #FEFEFE;
            -webkit-box-shadow: 4px 4px 14px -4px #d5e0eab9;  
            box-shadow: 4px 4px 14px -4px #d5e0eab9; 
            position: relative; 
            border: 1px solid rgba(128,128,128,.217);
            border-radius: 5px;
            overflow: hidden;
            transition : all 330ms ; 

            &--fullscreen {
                position: fixed;
                left: 20px;
                top: 20px;
                width: calc(100% - 40px);
                height: calc(100% - 40px) ;
                z-index : 3000 ; 
                transition : all 330ms ; 
            }

            & .tc-table__header--custom {
                width: 100%;

                & > div {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    & .edit-informations{
        width: calc(100%);
        background-color: #fdfdfd;
        border-radius: 10px;
        -webkit-box-shadow: 0px 3px 4px 2px rgba(0, 4, 0, 0.32);  
        box-shadow: 0px 3px 4px 2px rgba(0, 4, 0, 0.077);
        border: 1px solid rgb(228, 228, 228);
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        & .close-button {
            position: absolute;
            @include buttonStyle("icons");
            width: 25px;
            height: 25px;
            top: 10px;
            right: 10px;

            & div {
                display: flex;
                justify-content: center;
                align-items: center;
                & svg {
                    margin-left: 1px;
                }
            }
        }

        & > div {
            display: flex ; 
            flex-direction: column;
            align-items: center;
            width: 100% ; 
        }

        & label {
            @include labelStyle();
            font-size: 12px ; 
            letter-spacing: 0.25px ; 
        }
        
        & input {
            @include inputStyle();
            text-align: center; 
        }
        
        & .dropdown {
            @include dropdownStyle();
            width: calc(100% - 20px);
            text-align: center;
        }

        & .main-button {
            width: 100px ; 
            @include buttonStyle();
            margin-top: 10px;
        }
        


    }

    & .modals {
        display: flex;
        width: calc(75% - 30px);
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        -webkit-box-shadow: 4px 4px 14px -4px #d5e0eab9;  
        box-shadow: 4px 4px 14px -4px #d5e0eab9;  
        


        & .buttons {


            & .active {
                opacity: 1;

                & p {
                    font-weight: bold;
                    color : $color_3!important; 
                }

                

                & svg path {
                    fill : $color_3!important
                }
            }

            & > div {
                display: flex;
                align-items: center;
                margin: 0px 10px;
                opacity: 0.4;
                cursor: pointer;

                & .tc-icons {
                    width: 20px;
                    margin: 0px 5px;
                }

                &:first-child .tc-icons {
                    margin-left: 0;
                }

                & p {
                    margin: 0;
                    font-size: 15px;
                }


            }
        }

        & .selected-modal {
            width: 100%;
            height: calc(100% - 50px);
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            border-radius: 5px;
            transition: width height 1s;


            &__container {
                position: relative;
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                border: 1px solid rgba(128, 128, 128, 0.217);
                transition: all 0.4s;
                border-radius: 5px;
                //overflow: hidden;

                
            }

        }

        & .tableMagic {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }

        .tableMagicController {
            background-color: #fefefe;
        }

        & .fullscreen {
            margin: 0px;
            width: calc(100%)!important;
            height: 100%;
            z-index: 4000;
            position: fixed;


            & .selected-modal__background {
                width: 100%;
                height: 100%;
                backdrop-filter: blur(10px);
                top: 0;
                left: 0;
                position: absolute;
                background-color: rgba(74, 74, 74, 0.328);

            }

            & .selected-modal__container {
                z-index: 2000;
                width: calc(100% - 40px);
                height: calc(100% - 40px) ;
            }
        }




    }

    & .underconstruction {
        width: 500px;
        //height: 400px;
        position: absolute;
        bottom: 10px;
        right: 0px;
        justify-content: flex-end;
        //align-items: flex-end;
    }
}