@mixin labelStyle() {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color : $color_1 ; 
}

@mixin inputStyle($version:"default") {
    @if $version == "default" {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $color_3;
        font-size: 14px;
        height: 30px;
        font-family: $font;
        opacity : 0.7 ; 
        padding-left: 10px;
        color : $color_1 ; 
        font: $font;
    
        &::placeholder {
            color: $color_1;
            opacity: 0.5;
            font-style: italic;
        }
    
        &:focus {
            outline: none;
            background-color: rgba(0, 187, 0, 0.15);
            opacity: 1;
        }
    
        &:-webkit-autofill {
            font-size: 15px;
        }
    }

    @else if $version == "search" {
        background-color: transparent;
        border: none;
        //border-bottom: 2px solid $color_3;
        border: 1.5px solid rgba(128, 128, 128, 0.79);
        border-radius: 10px;
        background-color: white;
        font-size: 14px;
        height: 30px;
        color : $color_1 ; 
    
        &::placeholder {
            color: $color_1;
            opacity: 0.5;
            font-style: italic;
        }
    
        &:focus {
            outline: none;
            border: 1.5px solid $color_3;
            opacity: 1;
        }
    
        &:-webkit-autofill {
            font-size: 15px;
        }
    }

    @else {
        @error "Version d'input non prise en charge.";
    }
    
}

@mixin buttonStyle($version: "default") {
    @if $version == "default" {
        background: $color_3;
        display: inline-flex;
        padding: 0px 10px!important;
        height: 30px;
        border-radius: 10px;
        font-size: 14px;
        color: white;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        align-items: center;
        
    }

    @else if $version == "icons" {
        border-radius: 50%;
        cursor: pointer;
        background-color: $color_3;
        display: flex;
        justify-content: center;
        align-items: center;

        & .tc-icons {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            & svg {
                max-width: 50%;
                max-height: 50%;
            }

        }

        &:hover {
            background-color: $color_4;
            
        }
    }

    @else {
        @error "Version d'input non prise en charge.";
    }
}

@mixin dropdownStyle($version:"basic") {
    @if $version == "basic" {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border: 1px solid rgba(1, 54, 1, 0.129) ;
        border-radius: 5px;
        position: relative;
        cursor: default;
        z-index: 0 ; 

        & > .tc-icons {
            position: absolute;
            right: 10px;
            width: 15px;
            height: 15px;
            transition : all 110ms ; 

            & svg {
                width: 80%;
                height: 80%;
            }
        }


        &--list {
            position: absolute;
            border: 1px solid rgba(1, 54, 1, 0.129) ;
            top : 92% ; 
            //padding-top: 5%!important;
            z-index: 2000;
            width: calc(100% - 0px);
            background-color: white;
            //padding: 10px;

            &::after {
                content : "" ; 
                position: absolute;
                display: flex;
                height: 10px;
                width: 100%;
                top: -10px;
            }

            

            & > div {
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                cursor: pointer;
                font-size: 14px;

                &:hover {
                    background-color: #5dac3210;
                }
            }

            //display: none;

        }
        
    }

    @else {
        @error "Version d'input non prise en charge.";
    }
}