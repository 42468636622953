$font : 'Roboto Slab', serif;
$background_app : #fefefe;


$color_1: #116633; /* Très foncé */
$color_2: #1f854d; /* Foncé */
$color_3: #35a464; /* Un peu plus clair */
$color_4: #4bba7a; /* Encore plus clair */
$color_5 : #8DDB43 ; 

$color_gradient: linear-gradient(126deg, rgba(31, 133, 77, 1) 10%, #229355 100%);



@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}