

.reassignment {


    & .content {
        width: 700px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--newAffair {
        width: 300px;
        padding: 10px 20px 20px 10px;
        flex: 1 ;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid rgba(31, 86, 42, 0.271);

        & label {
            text-align: center;
            @include labelStyle()
        }

        & #search-select {
            width: 90%;
        }
    }

    &__result {
        width: calc(100% - 40px);
        height: 300px;
        display: flex;
        flex : 1 ; 
        margin: 10px 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
            width: calc(50% - 20px);
            height: 200px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding : 10px; 
            margin: 10px;
            border: 1px solid rgba(128, 128, 128, 0.462) ;
            border-radius: 10px;

            & > div {
                width: calc(100% - 10px);
                height: calc(90px - 10px);
                padding: 5px;
                border: 1px solid rgba(128, 128, 128, 0.462) ;
                border-radius: 5px; 
                margin-bottom: 10px;
                transition: 60ms ease;
                position: relative;

                &:hover {
                    border: 1px solid $color_3;
                    -webkit-box-shadow: 0px 6px 8px -6px rgba(0,0,0,0.34); 
                    box-shadow: 0px 6px 8px -6px rgba(0,0,0,0.34);
                }

 
                & #code {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    & p {
                        width: 100%;
                        margin: 0!important;
                        font-size: 15px;
                        font-weight: bold;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                & #info {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    opacity: 0.8;

                    & p {
                        width: 100%;
                        margin: 0!important;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-style: italic;
                    }
                }

                & #doc_number {
                    position: absolute;
                    bottom: 5px;
                    right: 10px;
                    font-weight: bold;
                    color: rgb(16, 16, 16);
                }
                


            }
        }

        &--old {
            & #code p {
                text-decoration: line-through;
                color: rgb(193, 2, 2);
            }
        }

        &--arrow {
            $rassignement-arrow-size : 10px ; 
            width: $rassignement-arrow-size!important;
            height: $rassignement-arrow-size!important;
            position: absolute;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%!important;



            & * {
                border: none!important;
            }

            & .tc-icons {
                width: 99%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0!important;
                padding: 0!important;
                transform: rotate(-90deg);
            }
        }

        &--new {
            & #code p {
                color: rgb(2, 88, 193);
            }
        }

        &--noSelectedAffair {
            height: 200px!important;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: none !important;
            margin: 0px !important;

            & svg {
                height: 80%;
            }
        }
    }
}