
.memory-aid {
    & .basepopup__container {
        width: 90%;
        height: 80%;
    }

    & .content {
        display: flex;
        width: 100%;
        padding: 0;
        height: calc(100% - 30px);
        overflow: hidden;
    }

    & .mini-load {
        & svg {
            width: 30px;
            height: 30px;
        }
    }

    & .app {
        margin: 0;
        width: 100%;
        height: 100%;

        & .stepsText {
            display: none;
        }
    }
}