.affairs {

    & .tc-table__thead {
        background: $color_3;
    }
    
    & .mini-load .tc-icons {
        width: 40px;
    }

    & .stepsText {
        position: fixed;
    }

    & .tc-table__header--custom {
        justify-content: flex-end;
    }
}