

.categories-rh-config {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;


    & > div {
        display: flex;
        border-radius: 15px;
    }

    & > div {
        border: 1px solid rgb(202, 202, 202);
        -webkit-box-shadow: 0px 5px 13px -2px rgba(0,0,0,0.38); 
        box-shadow: 0px 5px 13px -2px rgba(0,0,0,0.38);
    }

    &--listing {
        width: calc(300px - 20px);
        height: calc(100% - 20px - 30px);
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background-color: $color_1;


        & .add-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: 30px;
            position: relative;
            cursor: pointer;

            &--active {

                & .add-button--icon{
                    background-color: $color_3!important;
                }
                & p {
                    background-color: $color_3!important;
                }
            }


            &--icon {
                width: 30px;
                height: 100%;
                padding-right: 20px;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 0;
                background-color: $color_2;
                z-index: 1000;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;

                & .tc-icons, & .tc-icons svg {
                    margin-left: 2px;
                    width: 75%;
                    height: 75%;
                    
                }
            }

            & p {
                width: calc(100% - 30px - 10px);
                padding-left: 10px;
                height: 100%;
                color: white;
                background-color: $color_2;
                display: flex;
                align-items: center;
                border-radius: 20px;
                z-index: 1201 ; 
            
            }

            &::after {
                content: "";
                width: 100%;
                height:1px;
                position: absolute;
                background-color: rgba(255, 255, 255, 0.42);
                bottom: -10px;
                left: 0;
                border-radius: 15px;
                z-index: 1200;
            }
        }

        &__container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: calc(100% - 40px);
            overflow-y: scroll;
            position: relative;

            & > div {
                display: flex;
                flex-direction: column;
                & > div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 100%;
                    height: 30px;
                    margin: 5px 0px;
                    position: relative;
                    cursor: pointer;

                    &:first-child {
                        margin-top: 20px;
                    }
        
        
        
                    & .delete-button--icon {
                        width: 30px;
                        height: 100%;
                        padding-right: 20px;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        left: 0;
                        z-index: 1000;
                        border-top-left-radius: 20px;
                        border-bottom-left-radius: 20px;
        
                        & .tc-icons, & .tc-icons svg {
                            margin-left: 2px;
                            width: 70%;
                            height: 70%;
                            
                        }
                    }
        
                    & p {
                        width: calc(100% - 30px - 10px);
                        padding-left: 10px;
                        height: 100%;
                        color: white;
                        display: flex;
                        align-items: center;
                        border-radius: 20px;
                        z-index: 1201 ; 
                    
                    }
                }
            }

            & .no-data {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                & svg {
                    width: 40%;
                }
                & p {
                    height: 30px;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            &__category {
                transition: all 0.2s ease-in-out;
                border-radius: 5px;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.129);
                }
                &--active {
                    background-color: white!important;


                    & p {
                        color : $color_1!important
                    }

                    & svg path {
                        fill : $color_1!important
                    }
                }
            }
        }
    }

    &--container {
        width: calc(100% - 300px - 40px);
        height: calc(100% - 30px);
        background-color: #FFFFFF;
        flex-direction: column;
        
        &__title {
            width: 100%;
            height: calc(60px - 10px);
            margin: 10px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            & input {
                width: 300px;
                background-color: red;
                border: none;
                background-color: transparent;
                text-align: center ; 
                @include inputStyle() ; 
                &:focus {

                }
            }
        }

        &__structure {
            height: calc(100% - 60px);
            display: flex;
            flex-direction: column;
            align-items: center;

            & > div {
                flex-direction: column;
                height: calc(100%);
            }

            & div {
                width: calc(100% - 10px);

                display: flex;

                & > div {
                    display: flex;
                }
            }
            
            &__add {
                position: relative;

                & .tc-icons {
                    width: 30px;
                    height: 25px;

                    & svg {
                        height: 70%;
                    }
                }

                & input {
                    display: flex;
                    flex: 1 1;
                    border: none;

                    &:focus {
                        outline : none
                    }

                    &::placeholder {
                        font-style: italic;
                        color: rgba(128, 128, 128, 0.524);
                    }
                }

                & .button-submit {
                    position: absolute;
                    right: 0px;
                    width: auto;
                    cursor: pointer;

                    & svg {
                        & path {
                            &:last-child {
                                opacity: 0;
                            }
                        }
                    }
                }
            }

            &__list {
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                height: calc(100% - 25px);

                & > div {
                    display: flex;
                    flex-direction: column;
                    height: calc(100% - 25px);
                    & > div {
                        margin: 5px 0px;
                    }
    
                    & .button-delete {
                        width: 30px;
                        height: 25px;
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
    
                        & svg {
                            height: 70%;
                        }
                    }
    
                    & input {
                        display: flex;
                        flex: 1 1;
                        border: none;
                        background: transparent;
    
                        &:focus {
                            outline : none
                        }
    
                        
                    }
                }


            }
        }



    }
}