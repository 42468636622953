.navigation {
    width: 0px;
    display: flex;
    position: fixed;
    left: 0;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition : width 220ms ; 
    overflow: hidden;
    user-select: none;
    z-index: 3000;



    & .logo {
        margin-top: 10px;
        width: 100%;
        height: 70px;
        display: flex;
        overflow: hidden;

        & > div {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition : all 100ms ; 
            


        }

        & .minified {
            width: 110%;
            left : -3px ; 
            height: 50px;

            & img, svg {
                width: 50%;
            }


        }


        & .normal {
            left : -500px; 

            & img, svg {
                width: 80%;
            }
        }


        & svg {
            width: 85%;
        }
    }

    & .menu-icon {
        width: 50px;
        height: 50px;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        & .tc-icons {
            width: 60%;
            height: 60%;
            transition: background-color 100ms;
            border-radius: 5px;

            & svg {
                width: 60%;
                height: 60%;
            }
        }

        &:hover {
            & .tc-icons {
                background-color: $color_3;

                & svg path {
                    fill: white!important;
                }
            }

            & + .navigation__container {
                left: 10px;
                opacity: 1;
            }
        }
    }

    &__container {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: fixed;
        width: 300px;
        height: calc(100% - 100px - 20px);
        padding: 10px 0px;
        background-color: white;
        border: 1px solid #8a898978;
        box-shadow: 0px 0px 5px 3px rgba(3, 9, 2, 0.857);
        -webkit-box-shadow: 0px 0px 5px 3px rgba(212, 212, 212, 0.821); 
        left: -400px;
        opacity: 0;
        transition: left 220ms;
        border-radius: 10px;

        &::after {
            content : "" ; 
            width: 15px;
            height: calc(100% + 20px);
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            left: -15px;
        }

        &:hover {
            left: 10px;
            opacity: 1 ; 
        }

        & .download {
            cursor: pointer;

            & p {
                text-align: justify;
            }
            & svg {
                width: 20px;
                padding: 2.5px;
            }
        }

        & > div {
            width: calc(100% - 20px);
            height: 35px;
            margin : 2px 0px ;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            & .active {
                background: $color_3;
                border-radius: 5px;
                & svg path {
                    fill : white ; 
                }

                & p {
                    color: white!important;
                }
            }

            & > a, button {
                width: calc(100% - 15px);
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                background-color: transparent;
                border: none;
                overflow: hidden;
                padding-left: 15px;
                justify-content: space-between;

                & div {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & svg {
                        width: 15px;
                        margin-bottom: 2px;

                        & path {
                            fill : $color_1
                        }
                    }
                }
                & p {
                    font-size: 13px;
                    overflow: hidden;
                    font-weight: 400;
                    opacity: 0;
                    color: $color_1;
                    width: 100px;
                    height: 100%;
                    opacity: 1;
                    flex: 1 1;
                    padding-left : 10px ; 
                    position : relative ; 
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
    
                    & span {
                        position: absolute;
                        width: 200px;
                        left: 10px;
                    }



                }
            }

        }
    }

    & .logout{
        width: 100%;

        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        & div {
            padding : 0px 10px ; 

            & svg {
                width: 20px;
                & path {
                    fill : rgb(148, 1, 1) ; 
                }
            }
        }

        & p {
            width: 0;
            overflow: hidden;
            font-weight: 700;
            color : rgb(148, 1, 1) ; 
            cursor: pointer;
            opacity: 0;
            font-size: 13px;

        }


    }


/*
    &:hover {
        width: 230px;
        box-shadow: 3px 1px 12px 0px #1414141a;
        z-index: 3000;

        & .logo {
            & > div {
    
                & img, svg {
                    
                }
            }
    
            & .minified {
                width: 20px;
                left : 500px
            }
    
    
            & .normal {
                left : 0px
            }
    
    
            & svg {
                width: 150px ;
            }
        }

        & .navigation__container {

            & > div {
                & > a, button {
                    overflow: hidden;
                    padding-left: 10px;
                    justify-content: space-between;

                    & p {
                        width: 100px;
                        height: 100%;
                        opacity: 1;
                        flex: 1 1;
                        padding-left : 10px ; 
                        position : relative ; 
                        display: flex;
                        align-items: center;
                        justify-content: center;


                        & span {
                            position: absolute;
                            width: 200px;
                            left: 10px;
                        }
                    }
                }
            }
        }

        & .logout{
            width: 100%;
            
            height: 50px;
            display: flex;
    
            align-items: center;
            cursor: pointer;
    
            & div {
                padding-left: 20px;
    
                & svg {
                    width: 20px;
                    & path {
                        fill : rgb(148, 1, 1) ; 
                    }
                }
            }
    
            & p {
                width: 200px;
                font-weight: 700;
                opacity: 1;
                color : rgb(148, 1, 1) ; 
                cursor: pointer;
            }
    
    
        }

    }

    */


}
