
.new-sale {


    & .basepopup__container {
        width: auto;
        height: auto;
        max-height: inherit;
    }

    & .content {
        padding: 0;
        width: 100%;

        height : auto;
        //overflow: hidden;
    }

    & .sales-processing {
        width: calc(100vw - 50px);
        height: calc(100vh - 100px);
        display: flex;
        align-items: center;

        &__structure {
            width : calc(100% - 400px);
            height : calc(100% - 40px) ; 
            padding: 20px 0px 20px 0px;
            display: flex;
            overflow-y: scroll;
            flex-wrap: wrap;
            justify-content: center;
            align-content: flex-start;

            & > .element {
                width: calc(50% - 20px - 2px);
                max-width: 450px;
                background-color: #fEFEFE;
                border-radius: 5px;
                border : 1px solid #E0E0E0;
                margin: 10px 10px;
                min-height: 200px;
                max-height: 300px;
                -webkit-box-shadow: 0px 7px 9px -2px rgba(0,0,0,0.36); 
                box-shadow: 0px 7px 9px -2px rgba(0, 0, 0, 0.11);  

                & .title {
                    width: calc(100%);
                    height: calc(40px - 10px);
                    background: $color_gradient;
                    color: white;
                    padding: 5px 0px;
                    font-weight: 600;
                    font-size: 15px;
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                }
                
                & .list {
                    height: calc(100% - 40px - 70px);
                    display: flex;
                    flex-direction: column;
                    overflow-y: scroll;

                    & > div > div {
                        width: 100%;
                        height: 30px;
                        display: flex;

                        & > div {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            &:hover {
                                background-color: (142,246,142,.326)
                            }
                        }

                        & .code {
                            width: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        & .designation {
                            width: calc(100% - 40px - 100px);
                        }

                        & .amount {
                            width: calc(100px - 10px);
                            padding-right: 10px;
                            display: flex;
                            justify-content: flex-end;

                            & input {
                                width: 100%;
                                display: inline-flex;
                                border: none;
                                border-radius: 5px;
                                text-align: end;
                                margin-right: 2px;
                                transition : all 220ms ; 

                                &:focus {
                                    outline: none;
                                    background-color: rgba(66,202,66,.328);
                                }
                                
                            }
                        }

                        & .percent {
                            width: 65px;
                            justify-content: flex-end;
                            flex-direction: row;
                            padding-right: 5px;
                        }
                    }
                }

                & .totals {
                    width: 100%;
                    height: calc(70px - 10px);
                    padding-top : 10px;
                    display : flex ; 
                    flex-direction: column;

                    & > div {
                        padding: 0px 10px;
                        width: calc(100% - 20px);
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        height: 30px;
                        
                        & p {
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            &:first-child {

                            }

                            &:last-child {
                                width: 100px;
                                margin-left: 10px;
                            }
                        }
                    }

                    & svg {
                        width: 15px;
                        height: 15px;
                        margin: 0px 2.5px;
                    }

                    
                }
            }
        }


        &__costSummary {
            width: 400px;
            height: calc(95% - 10px);
            border-left: 1.5px solid rgba(128, 128, 128, 0.307);

            &--header {
                width: calc(100% - 20px);
                margin: 0px 10px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(40px - 5px);
                padding-bottom: 5px;
                border-bottom: 1.5px solid rgba(128,128,128,.307);

                & .button-return, .button-save {
                    z-index: 1000;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    padding: 0!important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none ; 
                    @include buttonStyle("icons") ;
            
            
                    & svg {
                        width: 50%;
                        height: 50%;
                    }

                    & p {
                        position: absolute;
                        top: 70%;
                        background-color: #fff;
                        border: 0.5px solid #a9a9a9;
                        padding: 5px 10px;
                        border-radius: 10px;
                        font-size: 13px;
                        color: #000;
                        display: none;
                    }

                    &:hover p {
                        display: flex;
                    }
                }

                & .button-return {
                    left: 10px;

                    & p {
                        left: 0px;
                    }
                }

                & .button-save {
                    right: 10px;
                    background-color: rgb(0, 153, 199);

                    & p {
                        right: 0px;
                    }

                    &:hover {
                        background: rgb(1, 127, 165)!important
                    }
                }
            }

            &--container {

                width: calc(100% - 20px);
                margin: 0px 10px;

                    & .environment-variables {
                        width: calc(100% - 20px);
                        border-bottom: 1.5px solid rgba(128,128,128,.307);
                        padding-bottom: 20px;
                        padding: 10px;

                        & h2 {
                            font-size: 17px;
                            margin: 0;
                            color: $color_1;
                            text-align: center;
                            font-style: italic;
                            opacity: 0.8;
                        }
                        & > div {
                            flex-direction: column;
                            align-items: flex-start;
                            & label {
                                @include labelStyle() ; 
                                font-size: 15px;
                                margin: 5px 0px
                            }

                            & input {
                                @include inputStyle() ; 
                                height: 25px;
                            }
                        }
                    }

                    & > div {
                        width: 100%;
                        margin-top: 10px;
                        display: flex ; 
                        flex-direction: column;

                        & > div {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            margin : 5px 0px ; 
                            &:hover {
                                background-color: (142,246,142,.326)
                            }
                            & .code {
                                width: 40px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
    
                            & .designation {
                                width: calc(100% - 40px - 100px);
                            }
    
                            & .amount {
                                width: calc(100px - 10px);
                                padding-right: 10px;
                                display: flex;
                                justify-content: flex-end;
    
                                & input {
                                    width: 100%;
                                    display: inline-flex;
                                    border: none;
                                    border-radius: 5px;
                                    text-align: end;
                                    margin-right: 2px;
                                    transition : all 220ms ; 
    
                                    &:focus {
                                        outline: none;
                                        background-color: rgba(66,202,66,.328);
                                    }
                                    
                                }
                            }
                        }

                        & .cost-price {
                            font-weight: bold;
                            padding-bottom: 15px;
                            border-bottom: 1.5px solid rgba(128,128,128,.307) ; 
                        }

  
                    
                    }
            }
        }
    }

    & .invoice-import {
        & > div {
            &:first-child {
                margin-bottom: 20px;
            }
        }
    }
}