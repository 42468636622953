


.suppliers-manager {
    & .main-button {
        margin : 5px 0px ; 
    }





}