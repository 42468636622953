html, body, #root {
    width: 100%;
    height: 100%;
    display: flex;
    font-family: $font!important;
    background-color: $background_app ;
    overflow: hidden;
    user-select: none;
    margin: 0;
}

.tc-datepicker__day--selected {
    background-color: $color_3;
}

.tc-datepicker__day--keyboard-selected {
    background-color: rgba(1, 180, 1, 0.235)!important;
}

.tc-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

h1 {
    color : $color_1 ; 
    font-size: 18px;
}

p {
    color : $color_1 ; 
    font-size: 14px;
}

.app {
    width : calc(100%) ; 
    height: calc(100% - 50px);
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1;
        height: calc(100% - 0.5px);
        background-color: #F4F5F6;
        -webkit-box-shadow: 0px -9px 5px -6px rgba(2,13,6,0.14); 
        box-shadow: 0px -9px 5px -6px rgba(2,13,6,0.14);
        border-top : 0.5px solid rgba(128, 128, 128, 0.249) ; 
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
    }

}

.underconstruction {
    width: calc(100% - 50px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 50px;
    & svg {
        height: 50%;
    }

    & p { 
        font-size: 22px;
        font-weight: bold;
    }
}


.affairs, .suppliers, .invoicing, .payments, .sales, .documents {

    & > .tc-table {
        & .tc-table__thead {
            background-color: $color_3;
        }
    }


}

.tc-table {
    & .actions {
        & .trash svg path {
            fill : rgb(194, 2, 2)!important
        }
    }
    &__header {

        & .return svg path {
            fill : red!important
        }

        & .save svg path {
            fill : rgb(1, 101, 177)!important
        }

        & .editing-active svg path {
            fill : #bd7e00!important
        }

        &--custom {
            width: calc(100% - 20px) ; 
            margin: 0px 10px;
            align-items: center;
        }
    }

    &__thead {
        background: $color_3;
    }

    &__tbody {

        & input,div, p {
            font-family: $font;
            font-size: 13px;
            color: $color_1;
        }

        & .events {
            &-select {
                background-color: rgba(66,202,66,.328);
            }
        }
        
        &--row{
            transition : none!important ; 
            font-size: 13px;
            &:hover {
                background-color: rgba(142,246,142,.326);
            }

            &--column {
                transition : all 20ms ; 
            }
        }

        & .editing {

            & > input {
                font-family: $font;
                font-size: 13px;

                &:focus {
                    background-color: #48aa2a34;
                }
            }
        }

        & .description {

            &--input {
                width: 100%;
                height: 100%;
                display : flex; 
                justify-content: flex-end;
                &--linked {
                    width: 100%;
                    height: 100%;
                    & input {
                        width: calc(100% - 30px)!important;
                        padding-left: 30px!important;
                    }
                }
            }
            &--linked {
                width: calc(100% - 30px - 10px);
                padding-left: 30px;
                padding-right: 10px;
            }
        }

        & .unit, .total_ht {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;

            &--input input {
                text-align: center;
            }

            &:hover {
                background-color: rgba(128, 128, 128, 0.271);
                cursor : not-allowed;
            }
        }

        & .total_ht {
            display: flex;
            width: calc(100% - 20px);
            height: 100%;
            padding: 0px 10px;
            justify-content: flex-end;
            align-items: center;
            font-weight : bold ; 

            &:hover {
                background-color: rgba(128, 128, 128, 0.271);
                cursor : not-allowed;
            }
        }

        & .pricing {
            width: calc(100% - 20px);
            padding: 0px 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: end;
            &--input {
                display: flex;
                & input {
                    text-align: end;
                    
                }

                & div {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                }
            }
        }

        & .quantity--input input {
            text-align: center;
        }

        & .input {
            &--quantity {
                text-align: center;
            }
        }
    }

    &__footer {
        background: $color_3!important;
    }

    &--custom-filter {
            display: flex;
            flex-direction: row;
            //width: 200px;
            align-items: center;
            justify-content: space-between;
            height: 35px;
            border-radius: 30px;
            background-color: rgb(241, 241, 241);
            border: 1px solid rgb(218, 213, 202);
            position: relative;
            -webkit-box-shadow: inset 0px 1px 5px 0px rgba(2,13,6,0.2); 
            box-shadow: inset 0px 1px 3px 0px rgba(2, 13, 6, 0.167);

            & .selector {
                height: calc(100% - 4px );
                border-radius: 30px;
                position: absolute;
                background-color: #ffffff;
                border : 1px solid rgb(224, 230, 219) ; 
                z-index: 1000;
                transition : left 120ms ; 
            }

            & .filter__item {

                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                z-index: 2000;
                cursor: pointer;

                & p {
                    font-size: 12px;
                    &:first-child {
                        filter: grayscale(100%);
                    }

                    &:last-child{
                        height: 80%;
                        border-radius: 30px;
                        padding: 2.5px 5px;
                        margin-left: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                &--active {
                    & p {
                        font-weight: bold;
                    }

                }

                &--all {
                    & p:last-child {
                        color: rgb(0, 95, 147);
                        background-color: #e3e9fc;
                    }
                }

                &--travaux {
                    & p:last-child {
                        color: rgb(147, 86, 0);
                        background-color: #fcefe3;
                    }
                }

                &--entretiens{
                    & p:last-child {
                        color: rgb(137, 147, 0);
                        background-color: #f8fce3;
                    }
                }

                &--sav {
                    & p:last-child {
                        color: #b67600;
                        background-color: #ffb22344;
                    }
                }

                &--sent {
                    & p:last-child {
                        color: #5a21ce!important;
                        background-color: #9470db23!important;
                    }
                }



            }
        
    }
}

.totals-view {
    position: absolute;
    bottom: 0;
    left: 0px;
    height: 30px;
    display: flex;
    align-items: center;
    z-index: 2000;
    width: 180px;
    padding : 0px 10px ; 

    & p {
        width: 100%;
        height: 100%;
        //background-color: $color_2;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: default;

    }

    & > p {
        height: calc(100% - 2px);
        margin: 0;
        font-weight: bold !important;
        display: flex;
        justify-content: space-between;
        align-items: center; 
        z-index: 2000;
    }

    &--detail {
        width: calc(100%);
        position: absolute;
        background-color: $color_3;
        //bottom: calc(100% - 2px);
        left: 0px;
        z-index: 1000;

        & p {
            width: calc(100% - 20px);

            padding : 0px 10px ; 
            opacity: 0.9;
            height: 40px;
            margin: 0;


            &:hover {
                background-color : $color_4 ; 
            }

        }
        
    }
}

.dropdown {
    @include dropdownStyle() ; 
}

.listing-category {
    height: 30px;
    display: flex;
    align-items: center;
    background-color: $color_2;
    padding: 0px 10px;
    margin: 0px 5px;
    color: white;
    position: relative;
    z-index: 2000;
    border-radius: 10px;

    & > .tc-icons svg {
        width: 20px;
    }

    &__choosecat {
        background-color: $color_3;
        height: 25px;
        border-radius: 30px;
        margin: 0px 5px;
        display: flex;
        align-items: center;


        & > div {
            width: 15px;
            height: 15px;
            margin: 5px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 50%;

            & .tc-icons {
                height: 70%;
                display: flex;
                justify-content: center;
                align-items: center;

                & svg {
                    height: 100%;
                }
            }
        }

        & p {
            max-width: calc(130px - 15px);
            padding-left: 5px;
            padding-right: 10px;
            color : white ; 
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition : 220ms ease ; 

        }

        &:hover p {
            max-width: 300px;
        }

    }


    & .tc-icons {
        width: 20px;
        height: 20px;
        padding-right : 10px ; 
        z-index: 2000;
    }

    & p {
        color: white;
        z-index: 2000;
    }

    &__container {
        display:none;
        top: calc(100%);
        width: 300px;
        left: 0;
        position: absolute;
        padding-top: 5px;


        & > div {
            cursor: pointer;
            max-height: 250px;
            width: calc(100% - 40px);
            padding: 0px 10px;
            overflow-y: scroll;

            background: lighten($color_1, 5);
            flex-direction: column;
            z-index: 1000;
            border-radius: 10px;
            -webkit-box-shadow: 0px 9px 1px -4px rgb(0 91 0 / 40%);  
            box-shadow: 0px 9px 1px -4px rgb(0 91 0 / 40%);

            & > div {
                border-bottom: 1px solid rgba(255, 255, 255, 0.356);

                &:last-child {
                    border: none!important;
                }

                & p {
                    padding: 7.5px 0px;
                    margin: 0;
                }
            }
        }
    }

    &:hover {
        background-color: $color_1;

        & .listing-category__container  {
            display: flex;
        }

    }
}