

.full-pay-popup {
    z-index: 3001;

    & .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    & .basepopup__container {
        width: 400px;
    }

    & label {
        @include labelStyle() ; 
    }

    & textarea {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px 10px 10px 10px;
        margin-bottom: 10px;
        resize: none;
        border-radius: 10px;
        font-family: $font;
        border: 2px solid $color_2;

        &::placeholder {
            font-style: italic;
        }

        &:focus {
            outline: none;
        }
    }

    & .tc-datepicker-wrapper {
        margin-bottom: 10px;

        & input {
            @include inputStyle() ; 
            text-align: center;
            padding-left: 0px;
        }
    }



    & .main-button {
        width: 100px;
    }

    
}