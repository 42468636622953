

.documents--new-folder {

    & .basepopup__container {

    }

    & .content {
        flex-direction: column;

        & #name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 15px 30px;
            
            & label {
                @include labelStyle()
            }

            & input {
                @include inputStyle()
            }
        }
    }


}