

.change-status-popup {

    & label {
        @include labelStyle() ; 
    }

    & input {
        @include inputStyle() ; 
        text-align: center;
    }

    & .content {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        & > div {
            margin: 5px 0px;
        }
    }

    & #new-status {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & .dropdown {
            width: 80%;
            & p {
                width: calc(100% - 40px) ; 
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
    
            &--list {
                & > div {
                    justify-content: flex-start;
                    padding-left: 10px;
                    text-align: start;
                
                }
            }
        }
    }

    & #comment {
        width: 80%;


        & textarea {
            width: calc(100% - 20px);
            height: 100px;
            padding: 10px 10px 10px 10px;
            margin-bottom: 10px;
            resize: none;
            border-radius: 10px;
            font-family: $font;
            border: 2px solid $color_2;
    
            &::placeholder {
                font-style: italic;
            }
    
            &:focus {
                outline: none;
            }
        }
    }

 



    & #full-pay {
        & > div {
            display: flex;
            justify-content: center;
            & > div {
                width: 80px;
                height: 25px;
                font-size: 13px;
                border-radius: 10px;
                border: 1px solid rgb(209, 209, 209);
                color : #363636 ; 
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                cursor: pointer;

                &:first-child {
                    margin-right: 10px;
                    &:hover {
                        background-color: darken(#34C759, 10);
                        color: white;
                    }
                }

                &:last-child:hover {
                    background-color: darken(#FF3B30, 10);
                    color: white;
                }
            }

            & #full-pay__active--yes {
                background-color: #34C759;
                color: white;
            }

            & #full-pay__active--no {
                background-color: #FF3B30;
                color: white;
            }
        }
    }

    & .main-button {
        margin-top: 20px!important;
    }


}