

.config-rh {
    width: 100%;
    height: 100%;

    & .categorizer-manager {
        width: 100%;
        height: 100%;


        & .resize {
            display: none;
        }


        &__container {
            width: 100%;
            height: calc(100% - 50px);
        }
    }
}