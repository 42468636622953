

.invoicing {
    overflow-x: scroll!important;
    justify-content: flex-start;

    & .suppliers-creation{
        z-index : 3501
    }

    & .invoice-detail {

        & > .mini-load svg {
            width: 40px;
            height: 40px;
        }

        & .tc-table {

            &__header {
                position: absolute;
                left: 0;
                width: 100vw;

                &--custom {

                    & .export {
                        position: relative;

                        &:hover .export--menu {
                            display: flex;

                        }
                        &--menu {
                            display: none;
                            position: absolute;
                            left: 0px;
                            top: calc(100% + 10px);
                            z-index: 1000;
                            width: 250px;
                            background-color: white;
                            border: 1px solid #8a898978;
                            -webkit-box-shadow: 5px 5px 7px -1px rgba(0,0,0,0.27); 
                            box-shadow: 5px 5px 7px -1px rgba(0,0,0,0.27);
                            //display: flex;
                            flex-direction: column;
                            padding: 5px 10px;
                            border-radius: 10px;

                            & > div {
                                display: flex;
                                justify-content: flex-start;
                                text-align: start;
                                margin: 5px 0px;
                                color: $color_1;

                                &:hover {
                                    color: $color_3;
                                    font-weight: 600;
                                }
                            }

                            &::before {
                                content : "" ; 
                                position: absolute;
                                width: 100%;
                                top: -10px;
                                left: 0px;
                                height: 10px;
                                //background-color: red;
                            }
                        }
                    }
                }
            }

            & .mode-business-manager {
                height: 30px;
                padding: 0px 10px;
                margin: 0px 5px;
                border: none;
                border-radius: 45px;
                font-size: 14px;
                cursor: pointer;
                background-color: #2196f3;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                &:hover {
                    background-color: #0d47a1;   
                }

                & p {
                    color: white;
                }

                & .tc-icons {
                    width: 15px;
                    height: 15px;
                    margin-right: 2.5px;
                    margin-top: .5px;

                    & svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                &--active{
                    background-color: #0d47a1;
                }
            }

            & .memory-affair-aid {
                width: 25px;
                height: 25px;
                margin: 0px 10px 0px 5px;
                cursor: pointer;
                z-index: 1300 ; 

                & svg {
                    width: 100%;
                    height: 100%;

                    & path {
                        fill : #027aff ; 
                    }
                }

                &:hover {
                    &::after {
                        display: flex;
                    }

                    & svg path {
                        &:first-child {
                            opacity: 1;
                        }

                        &:last-child {
                            opacity: 0;
                        }
                    }
                }

                &::after {
                    content : "Aide mémoire des affaires" ; 
                    display: none;
                    background-color: #fff;
                    border: .5px solid #a9a9a9;
                    border-radius: 10px;
                    font-size: 13px;
                    top: 70%;
                    color: #000;
                    width: 170px;

                    padding: 5px 10px;
                    box-shadow: 5px 5px 7px -1px rgba(0, 0, 0, .27)
                }
            }

            &__footer--infos {
                position: fixed;
                right: 0px;
            }
            
            &__thead {
                margin-top: 50px;

                &--column {
                    font-size: 13px;
                }
            }


        }
    }

    & .stepsText {
        position: fixed;
    }

    & .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & svg {
            width: 400px;
            margin-bottom: 20px;
        }

    }

    & .table {
        width: 100%;
        height: 100%;
    }

    & .tc_payments_mode, .tc_comment {
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;

        & p {
            width:230px;
            margin: 0;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;

        }

    }


}


.invoicing-deposit {
    z-index: 3000;
    &--fullscreen .basepopup__container {
        width: 100%;
        height: 100%;
        max-height: inherit;

        & .content {
            width: 100%;
            height: calc(100% - 30px);
            padding: 0;
        }
    }

}

.invoice-import {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 250px;

    & #search-select {
        width : 350px
    }

    & > div:first-child {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;


        & label {
            margin: 10px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: bold;
            color : $color_1 ; 
        }
    
        & .choose-affair {
            width: 300px;
            z-index: 4000;
            margin-top: 10px;
            display: flex ; 
            justify-content: center ; 
            align-items: center ; 
            position: relative;

    
            & input {
                width: calc(100% - 40px);
                padding-left : 30px ; 
                padding-right: 10px ; 
                z-index: 2000; 
                @include inputStyle("search"); 
            }
    
            & .magnifying-glass {
                width: 15px;
                height: 15px;
                position: absolute;
                left: 10px;
                z-index: 2100; 
            }
    
            & .list {
                position: absolute;
                padding-top: 30px;
                top: 50%;
                width: calc(100% - 3px);
                background-color: white;
                border: 1.5px solid rgba(128, 128, 128, 0.79);
                border-radius: 10px;
                max-height: 250px;
                //padding: 10px 0px;
                padding-top: 15px;
                overflow-y: scroll;
    
                & > div {
                    font-size: 13px;
                }

                &--element {
                    width: calc(100% - 20px);
                    padding: 0px 10px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        background-color : rgba(142, 246, 142, 0.326)
                    }
                }

                &--background {
                    width: 100%;
                    height: 100%;
                    position: fixed;
                }

                & .mini-load .tc-icons {
                        width: 30px;
                        height: 30px;
                        margin: 10px 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        //margin: 5px 0px;


                }
    
                & .list--nodata {
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                }
            }
    
            
            
    
            
            
    
        }

    }

    & .import {
        margin: 30px 0px;
        width: 70%;
        height: 350px;

        &__uploadedFile {
            margin: 0;
        }



        & h1{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            font-size: 13px;
        }

        & p{
            font-size: 12px;
        }

        & .pending {
            height: 20px;
            margin-bottom: 5px;

            & svg {
            width: 15px;

            }
        }
    }



}

.invoice-processing {
    width: 100%;
    height: 100%;
    display: flex;

    & .preview {
        width: calc(100% - 400px);
        height: 100%;
        display: flex;
        position: relative;

        & iframe {
            width: 100%;
            border: none;
        }

        & .mini-load {
            background-color: rgba(238, 238, 238, 0.783);
            backdrop-filter: blur(10px);
            position: absolute;
        }

        & .no-data {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            user-select: none;
            
            & svg {
                height: 300px;
            }

            & p {
                width: 300px;
                line-height: 150%;
                font-size: 15px;
                font-weight: bold;
                text-align: center;

            }
        }

    }

    & .manager {
        width: 400px;
        height: 100%;
        display: flex;
        flex-direction: column;


        &--header {
            width: calc(100% - 10px);
            height: 40px;
            margin: 0px 5px ;
            display: flex;
            align-items: center;
            //background-color: orange ; 

            & .return, .delete {
                width: 25px;
                margin-right: 5px;
                height: 25px;
                position: relative;
                @include buttonStyle("icons")
            }

            & .return, .delete {
                &:hover::after {
                    display: flex;
                }

                &::after {
                    display: none;
                    background-color: #fff;
                    border: .5px solid #a9a9a9;
                    border-radius: 10px;
                    font-size: 13px;
                    top: 70%;
                    color: #000;
                    //width: 170px;
                    position: absolute;
                    z-index: 1000;
                    left: 0;
                    top: calc(100% + 5px);
                    padding: 5px 10px;
                    box-shadow: 5px 5px 7px -1px rgba(0, 0, 0, .27)
                }
            }

            & .return::after {
                content : "Retour" ; 
            }

            & .delete::after {
                content : "Supprimer" ; 
            }

            & .delete {
                background-color: rgb(199, 1, 1);

                &:hover {
                    background-color: rgba(255, 0, 0, 0.82);
                }

            }

            & .buttons {
                display: flex;
                flex: 1 1 ;
                width: calc(100% - 35px);
                height: 100%;

                //background-color: red;

                &--listing {
                    width : 100% ; 
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    & .save {
                        width: 30px;
                        height: 30px;
                        background: rgb(0, 153, 199)!important;
                        @include buttonStyle("icons") ; 
                        position: relative;

                        &:hover::after {
                            display: flex;
                        }
        
                        &::after {
                            content : "Enregistrer" ; 
                            display: none;
                            background-color: #fff;
                            border: .5px solid #a9a9a9;
                            border-radius: 10px;
                            font-size: 13px;
                            top: 70%;
                            color: #000;
                            //width: 170px;
                            position: absolute;
                            z-index: 1000;
                            right: 0;
                            top: calc(100% + 5px);
                            padding: 5px 10px;
                            box-shadow: 5px 5px 7px -1px rgba(0, 0, 0, .27)
                        }

                        &:hover {
                            background: rgb(1, 127, 165)!important
                        }
                    }
                }

                &--update {
                    width : 100%; 
                    height : 100% ; 
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;


                    & > div {
                        @include buttonStyle("icons") ; 
                        width: 25px;
                        height: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        & svg {
                            height: 55%;


                            & path {
                                fill: white;
                            }

                        }

                    }

                    & p {
                        margin: 0px 7.5px;
                        letter-spacing: 1px;
                        font-weight: 600;
                    }
                }
            }
        }

        &--container {
            width: 100%;
            height: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            //background-color: red;

            & .basepopup__container {
                width: auto;
                height: auto;
                user-select: none;

                & .content {
                    width: 500px;
                }
            }
            
        }

        & .to-revise {


            & h1 {
                background: linear-gradient(126deg, #f95454 0%, #cc0000 100%);
            }

            & .tbody--element {
                &:nth-child(2n + 1) {
                    background-color: #ba515129 ; 
                }

                &:hover {
                    background-color: #ba51515d;
                }


            }


            & .active {
                background-color: #c43c3c!important;
                color: white;
            }

            
        }

        & .ready {
            & h1 {
                background: $color_gradient;
            }

            & .tbody--element {
                &:nth-child(2n + 1) {
                    background-color: #51ba6929 ; 
                }

                &:hover {
                    background-color: #51ba695d;
                }
            }

            & .active {
                background-color: $color_3!important;
                color: white;
            }
        }
        
    }






}

.invoice-update {
    width: 100%;
    height: 100%;
    //background-color: rgb(239, 255, 237);
    display: flex;
    flex-direction: column;
    position : relative ; 
    overflow: hidden;

    & .status {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        position: absolute;
        font-size: 15px;
        text-align: center;
        color: white;

        &--to_revise {
            background: linear-gradient(126deg, #f95454 0%, #cc0000 100%);
        }

        &--ready {
            background: $color_gradient;
        }
    }

    & .form {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        & .buttons {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            & .active {
                opacity: 1!important;

                & p {
                    font-weight: bold;
                    color : $color_3!important; 
                }

                

                & svg path {
                    fill : $color_3!important
                }
            }

            & > div {
                display: flex;
                align-items: center;
                margin: 0px 10px;
                opacity: 0.4;
                height: 40px;
                cursor: pointer;

                & .tc-icons {
                    width: 20px;
                    height: 20px;
                    margin: 0px 5px;

                    & svg {
                        width: 90%;
                    }
                }

                &:first-child .tc-icons {
                    margin-left: 0;
                }

                & p {
                    margin: 0;
                    font-size: 15px;
                }


            }
        }

        .selected-modal {
            width: auto;
            height: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            & .type {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                z-index: 2000;

                & .dropdown {
                    width: 200px;
                }
            }

            & .id_supplier {
                display: flex;
                flex-direction: column;

            }

            & .id_supplier--supposed-supplier {
                display: flex;
                justify-content: flex-start;
                margin: 0;

                & span:first-child {
                    margin-right: 5px;
                }
            }

            & > p {
                font-size: 12px;
                font-style: italic;

                & span {

                    &:last-child {
                        cursor: pointer;
                        &:hover {
                        font-weight: bold;
                        text-decoration: underline;

                    }
                    }
                }
            }



        }

        & .more_affairs {

            & button {
                border : rgb(204, 203, 203) 1.5px solid ; 
                border-radius: 10px;
                padding: 5px 10px;
                background-color: rgb(235, 235, 235);
                font-family: $font;
                cursor: pointer;
            }
        }



        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: auto;


            & label {
                @include labelStyle()
            }

            & > div {
                display: flex;
                margin: 10px 0px;
                position: relative;
                
            }
        
            & .status_input {
                position: absolute;
                bottom : 5px ; 
                right: -30px;
                border-radius: 50%;

                & .tc-icons {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    & svg {
                        width: 10px;
                    }
        
                }
        
        
                &--xmark {
                    background-color: rgba(255, 0, 0, 0.171);
                }
            }
        }


        & .inv_number, .due_date, .date, .receipt_date {
            display: flex;
            flex-direction: column;
            align-items: center;



            & .tc-datepicker-popper {
                z-index: 2000;
            }


            & input {
                width: 150px;
                text-align: center;
                @include inputStyle()
            }
        }

        & .due_date {
            & .inv_on_receipt {
                $circle-checkbox-inv-on-receipt : 20px ;
                position: relative;
                display: flex;
                align-items: center;
                & .checkbox {
                    width: 40px;
                    height: calc($circle-checkbox-inv-on-receipt);
                    border-radius: 20px;
                    background-color: rgb(177, 177, 177);
                    display: flex;
                    align-items: center;
                    margin-right: 5px;
                    position: relative;
                    cursor: pointer;

                    & > div {
                        width: calc($circle-checkbox-inv-on-receipt - 5px);
                        height: calc($circle-checkbox-inv-on-receipt - 5px);
                        background-color: white;
                        border-radius: 50%;
                        transition : all 110ms ; 
                        left : 3px ; 
                        position: absolute;
                    }

                    &--true {
                        background-color: #2196f3;
                        & > div {
                            left : calc(100% - $circle-checkbox-inv-on-receipt + 2px ); 

                        }
                    }
                }
            }
        }

        & .inv_number, .due_date {
            & input {
                text-align: center;
            }
        }

        & .total_ttc, .doc_number {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            & div, input {
                width: 80px;
                text-align: center;
                font-weight: 400;
                color : white!important ; 
                background-color: rgb(46, 46, 46);
                letter-spacing: 0.5px;
                font-size: 13px;
                padding : 5px 10px ;
                opacity: 1;
                border-radius: 5px;
                border: none!important;
                
                &:hover {
                    opacity: 0.8;
                }

                &:focus {
                    outline: none;
                }
            }
    

    
        }

        & .total_tva, .total_ht {
            flex-direction: column;

            & > div {
                display: flex;
                align-items: center;
                & input {
                    width: 80px;
                    text-align: end;
                    @include inputStyle() ; 
                    padding-right : 5px 
                }
                & p {
                    margin: 0;
                }
            }

        }

        & .search-select {
            width: 200px;
        }

        & .comment {
            display: flex;
            flex-direction: column;
            width: 300px;
            
            & textarea {
                margin: 10px 10px;
                display: flex;
                width: calc(100% - 30px - 20px);
                flex: 1 1;
                padding: 10px 15px;
                resize: none;
                font-family: $font;
                border-radius: 10px;
                border: 1px solid rgb(0, 0, 0, 0.2);
                min-height: 200px;
                max-height: 300px;
                
                &:focus {
                    outline : none ; 
                    border : 1.5px solid $color_3
                }
            }
        }

    }

}

.manager-cat {
    transition : all 220ms ease-in ; 
    user-select: none;
    display: flex;
    flex-direction: column;
    flex : 1 1 ; 

    & h1 {
        position: relative;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        padding-left: 10px;
        width: calc(100% - 10px);
        height: 30px;
        font-size: 13px;
        margin: 0;
        color: white;
        cursor: pointer;

        & > div {
            position: absolute;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            right : 0 ; 

            & .tc-icons {
                width: 30%;
            }
        }
    }

    & > div {
        width: 100%;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        overflow: hidden;
        
        & .thead div, .tbody > div > div > div {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;

            &:nth-child(1) {
                width: 30%;
            }
            &:nth-child(2) {
                width: 40%;
            }
            &:nth-child(3) {
                width: 30%;
            }
        }

        & .thead {
            display: flex;
            height: 40px;
            display: flex;
            align-items: center;
            user-select: none;

            & > div {
                font-weight: bold;
                
            }
        }

        & .tbody {
            display: flex;
            flex-direction: column;
            width: 100%;
            height : calc(100% - 40px); 
            overflow: scroll;

            &--element {
                width: 100%;
                display: flex;
                height: 40px;
                cursor: pointer;
            }
        }

    }
}

.invoice-detail {
    z-index : 2500 ; 
    width: 100%;
    height: 100%;

    & .tc-table__thead {
        background-color: $color_3;
    }

    & .tc-table__tbody {
        & .tbody--noData {
            height : 150px ; 

            & .no-data {
                width: auto;
                height: 200px;
            }

            & h1 {
                margin-top: 10px;
            }
        }
    }

    & .basepopup__container {
        max-height: inherit;
        width: 95%;
        height: 95%;

        & .content {
            height: calc(100% - 30px);
            width: 100%;
            padding: 0;
            position: relative;
        }

        & .mini-load svg{
            width: 40px;
            height: 40px;
        }
    }

    &__header {
        height: 40px;
    }

    &__container {
        display: flex;
        flex-direction: row;
        height: calc(100% - 70px);
        overflow: hidden;

        
    }

    & .preview {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left : 0 ; 
        background-color: rgba(28, 28, 28, 0.758);
        backdrop-filter: blur(5px);

        z-index: 2500;
        display: flex;
        justify-content: center;
        align-items: center;

        &--container {
            z-index : 2500 ; 
            width: 90%;
            height: 90%;

            & iframe {
                width: 100%;
                border-radius: 10px ;
                border: 0.1px solid whitesmoke;
                height: 100%;
            }
        }

        &--background {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
        }

    
    }


    & .listing {
        width: 100%;
        height: 100%;
        position: relative;
    
        & .to-validate {
            & h1 {
                background: linear-gradient(126deg, #f99354 0%, #cc6600 100%);
            }
    
            & .tbody--element {
                &:nth-child(2n + 1) {
                    background-color: #ba725129 ; 
                }
    
                &:hover {
                    background-color: #ba72515d;
                }
    
    
            }
    
    
            & .active {
                background-color: #c4693c!important;
                color: white;
            }
    
            
        }
    
        & .valid {
            & h1 {
                background: $color_gradient;
            }
    
            & .tbody--element {
                &:nth-child(2n + 1) {
                    background-color: #51ba6929 ; 
                }
    
                &:hover {
                    background-color: #51ba695d;
                }
            }
    
            & .active {
                background-color: $color_3!important;
                color: white;
            }
        }
    }

    &-header {
        width: calc(100% - 20px);
        height: 100%;
        padding: 0px 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & .buttons {
            display: flex;
            flex: 1 1;
            &__actions, .export, .reassignment, &--change-status {
                padding: 5px 10px;
                margin: 0px 5px;
                border: none;
                border-radius: 40px;
                font-size: 14px;
                cursor: pointer;
                border: 1.5px solid rgb(216, 216, 216);
            }



            & .reassignment, &--change-status {
                position : relative ; 
                background-color: #2196F3;
                border: none;
                color: white;
                transition: background-color 0.3s ease;

                

                &:hover {
                    background-color: #0D47A1;
                    & .buttons__actions--list {
                        display: flex;
    
                    }
                }
            }

            &__actions {
                position : relative ; 
                background-color: #007aff;
                border: none;
                color: white;
                transition: background-color 0.3s ease;

                

                &:hover {
                    background-color: #0056b3;
                    & .buttons__actions--list {
                        display: flex;
    
                    }
                }


                &--list {
                    display: none;
                    position: absolute;
                    left: 0px;
                    top: calc(100% + 10px);
                    z-index: 1000;
                    min-width: 150px;
                    background-color: white;
                    border: 1px solid #8a898978;
                    -webkit-box-shadow: 5px 5px 7px -1px rgba(0,0,0,0.27); 
                    box-shadow: 5px 5px 7px -1px rgba(0,0,0,0.27);
                    //display: flex;
                    flex-direction: column;
                    padding: 5px 10px;
                    border-radius: 10px;

                    & > button {
                        display: flex;
                        justify-content: flex-start;
                        text-align: start;
                        margin: 5px 0px;
                        color: $color_1;
                        font-size: 15px;
                        border: none;
                        background-color: transparent;
                        cursor: pointer;
                    }

                    & .pending {
                        $pending-color : rgb(214, 140, 3) ;
                        color: $pending-color;

                        &:hover {
                            color: lighten($pending-color, 10);
                            font-weight: 600;
                        }
                    }

                    & .valid {
                        $valid-color : #4e922a ;
                        color: $valid-color;

                        &:hover {
                            color: lighten($valid-color, 10);
                            font-weight: 600;
                        }
                    }
            
                    & .not-valid {
                        $not-valid-color : #860c25 ;
                        color: $not-valid-color; 

                        &:hover {
                            color: lighten($not-valid-color, 10);
                            font-weight: 600;
                        }
                    }
        
                    & .to_send {
                        $to-send-color : #2180ce ;
                        color: $to-send-color;

                        &:hover {
                            color: lighten($to-send-color, 10);
                            font-weight: 600;
                        }
                    }

                    &::before {
                        content : "" ; 
                        position: absolute;
                        width: 100%;
                        top: -10px;
                        left: 0px;
                        height: 10px;
                        //background-color: red;
                    }

                }
            }
    

        }

        & .filter {
            display: flex;
            flex-direction: row;
            //width: 200px;
            align-items: center;
            justify-content: space-between;
            height: 35px;
            border-radius: 30px;
            background-color: #f1f1f1;
            position: relative;

            & .selector {
                height: calc(100% - 4px);
                border-radius: 30px;
                position: absolute;
                background-color: #FAFAFA;
                z-index: 1000; 
            }

            & .filter__item {

                /*display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                z-index: 2000;
                cursor: pointer;

                & p {
                    font-size: 11px;
                    &:first-child {
                        filter: grayscale(100%);
                    }

                    &:last-child{
                        height: 80%;
                        border-radius: 30px;
                        padding: 2.5px 5px;
                        margin-left: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }*/

                & p {
                    font-size: 11px;
                }

                &--active {
                     & p {
                        font-weight: bold;
                     }

                }

                &--all {
                    & p:last-child {
                        color: rgb(0, 95, 147);
                        background-color: #e3e9fc;
                    }
                }

                &--refused {
                    & p:last-child {
                        color: rgb(147, 27, 0);
                        background-color: #fce5e3;
                    }
                }

                &--valid{
                    & p:last-child {
                        color: rgb(0, 147, 66);
                        background-color: #e3fcf2;
                    }
                }

                &--to_validate {
                    & p:last-child {
                        color: #b67600;
                        background-color: #ffb22344;
                    }
                }

                &--sent {
                    & p:last-child {
                        color: #2180ce!important;
                        background-color: #70b6db23!important;
                    }
                }



            }
        }


    }

    & .tc-table {

        &__tbody--row--column {
            position: relative;
        }

        & .tippy {
            &-box {
                background-color: #FAFAFA!important;
                border: 1px solid rgb(218, 218, 218);
                -webkit-box-shadow: 5px 5px 4px -2px rgba(0,0,0,0.18); 
                box-shadow: 5px 5px 4px -2px rgba(0,0,0,0.18);
            }

            &-arrow::before {
                border-radius: 10px;
                & p {
                    color: $color_2!important;
                }
            }
        }
        .status_validation {
            padding : 5px 10px ; 
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            @keyframes blink {
                0% {
                  box-shadow: 0 0 5px rgba(33, 128, 206, 0.47); /* Légère ombre */
                }
                50% {
                  box-shadow: 0 0 10px rgba(33, 128, 206, 0.637); /* Ombre un peu plus intense */
                }
                100% {
                  box-shadow: 0 0 5px rgba(33, 128, 206, 0.448); /* Retour à l'ombre légère */
                }
              }
              

            & #inv-status-sent {
                position: absolute;
                background-color: #2180ce;
                left: 0;
                height: 100%;
                width: 10px;
                animation: blink 1s infinite; 
            }

            & .mini-load {
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: rgba(245, 245, 245, 0.805);
            }

            &--sent {
                color: #2180ce!important;
                background-color: #70c6db23!important;
            }

            &:hover .status_validation--message {
                display: flex;
            }

            &--message {
                position: absolute;
                width: 300px;
                max-height: 200px;
                z-index: 2000;
                margin-top: 5px;
                top: 0;
                left: calc(100% - 10px);
                display: none;

                &:hover {
                    display: flex;
                }

                & > div {
                    margin-left: 10px;
                    width: 20px;
                    & svg path {
                        fill : $color_1
                    }
                }

                & p {
                    flex: 1 1 ;
                    width: 100%;
                    max-height: 200px;
                    overflow-y: scroll;
                    margin: 0;
                    background-color: white;
                    display: flex;
                    align-items: flex-start;
                    padding: 10px;
                    border-radius: 5px;
                    border: 1.5px solid $color_3;
                }
            }

            &--tippy {
                display: flex;
                flex-direction: column;

                &--sent {

                    & > div {
                        &:first-child {
                            color: #2180ce!important;
                            background-color: #70dbff33!important;
                        }
                    }
    
                }

                & > div{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 15px;

                    & > div:first-child {
                        height: 20px;
                    }

                    & p {
                        display: flex;
                        justify-content: flex-start;
                        text-align: justify;

                        &:first-child{
                            margin: 5px 0px;
                            font-weight: 600;
                        }

                        &:last-child {
                            width: 250px;
                            flex-direction: column;
                            align-items: flex-start;
                            margin: 0;

                            & span {
                                font-style: italic;
                                &:first-child {
                                    margin-bottom: 3px;
                                }
                            }
                        }
                    }

                    &::before {
                        content : ""; 
                        position: absolute;
                        height: 1px;
                        border: 20px;
                        width: 70%;
                        bottom: -7.5px;
                        left : 50%; 
                        transform: translateX(-50%);
                        background-color: rgba(128, 128, 128, 0.504);
                    }

                    &:last-child {
                        &::before {
                            display: none;
                        }
                    }
                }
            }

            &__pending {
                & .mini-load .tc-icons {
                    background: rgba(255, 255, 255, 0.337);
                    backdrop-filter: blur(5px);

                    & svg {
                        width : 20px ;
                        height : 20px ;
                    }
                }
            }
        }
    }
}

.detailed-cat-list{
    transition : all 220ms ease-in ; 
    user-select: none;

    & h1 {
        position: relative;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        padding-left: 10px;
        width: calc(100% - 10px);
        height: 30px;
        font-size: 13px;
        margin: 0;
        color: white;
        cursor: pointer;

        & > div {
            position: absolute;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            right : 0 ; 

            & .tc-icons {
                width: 30%;
            }
        }
    }

    & > div {
        width: 100%;
        
        & .thead div, .tbody > div > div > div {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
        }

        & .thead {
            width: calc(100% - 20px);
            padding-left: 20px;
            display: flex;
            height: 40px;
            display: flex;
            align-items: center;
            user-select: none;

            & > div {

                font-weight: bold;
                
            }
        }

        & .tbody {
            display: flex;
            flex-direction: column;
            width: 100%;
            height : calc(100% - 40px); 
            overflow: scroll;

            & > div {
                padding-bottom: 40px;
            }

            &--element {
                width: calc(100% - 30px);
                padding-left: 30px;
                display: flex;
                height: 40px;
                cursor: pointer;
                position : relative; 
                align-items: center;
            }

            & .button-preview {
                position: absolute;
                left: 5px;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 50%;

                &:hover {
                    background-color: $color_2;

                    & svg path {
                        fill : white!important ; 
                    }
                }
            }
        }

        & .size {
            &--due_date {
                width: 100px;
            }
            &--inv_number {
                width: 100px;
            }
            &--name_supplier {
                flex : 1 1; 
                justify-content: flex-start!important;
            }
            &--total_ht {
                width: 100px;
            }
            &--total_tva {
                width: 100px;
            }
            &--total_ttc {
                width: 100px;
            }
            &--validation {
                width: 105px;
                display: flex;
                justify-content: flex-end!important;

                & .tc-icons {
                    margin: 0px 2.5px;
                    padding: 0px 2.5px;
                    width: 25px;
                    height: 25px;
                }
            }
        }

    }
}

.popup-message-refused {
    z-index : 3001 ; 

    & .basepopup__container {
        width: 350px;
        height: 300px;
    }

    & .content {
        height: calc(100% - 20px);
        display : flex ; 
        flex-direction: column;
        align-items: center;

        & p {
            margin: 5px 0px;
        }

        & textarea {
            margin: 10px 10px;
            display: flex;
            width: calc(100% - 30px - 20px);
            flex: 1 1;
            padding: 10px 15px;
            resize: none;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            font-family: $font;
            
            &:focus {
                outline : none ; 
                border : 1.5px solid $color_3
            }
        }
    }
}

.popup-invoice-preview {
    z-index  : 3000 ; 

    & .basepopup__container {
        width: calc(100% - 150px);
        height: calc(100% - 50px);
        max-height: inherit;
    }

    & .content {
        width: 100%;
        height: 100%;
        padding: 0;

        & iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }
}

.popup-inv-update {
    z-index: 3000;


    & .more_affairs {

        & button {
            border : rgb(204, 203, 203) 1.5px solid ; 
            border-radius: 10px;
            padding: 5px 10px;
            background-color: rgb(235, 235, 235);
            font-family: $font;
            cursor: pointer;
        }
    }

    & .edit-inv-affairs .content {
        flex-direction: column;
    }

    & .payment_deadline {
        display: flex;
        flex-direction: column;
        z-index : 1000 ; 

        & .dropdown {
            width : 200px
        }
    }

    & .basepopup__container {
        width: calc(100% - 40px);
        max-height: inherit;
        height: calc(100% - 40px);
    }

    & .content {
        width: 100%;
        height: 100%;
        padding: 0;
        flex-direction: column;
        display: flex;

        & > .mini-load svg {
            width: 30px;
        }
    }

    &--header {
        height : 50px ; 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position : relative ; 

        & .arrows {
            height: 30px;
            display: flex;
            border: 1px solid $color_1 ; 
            margin: 0px 10px;
            align-items: center;
            padding: 0px 5px;
            border-radius: 10px;
            cursor : pointer ; 
            position: absolute;

            &:hover {
                background-color : $color_2 ; 

                & p {
                    color : white
                }

                & svg path {
                    fill : white!important ; 
                }
            }

            & .tc-icons {
                margin-top: 2.5px;
                margin :5px ;
                display : flex ; 
                align-items: center;
            }

            & svg {
                width : 10px ; 
            }

            &--prev {
                left: 0;
                & svg {
                    transform: rotate(90deg);
                }
            }

            &--next {
                right: 0;
                & svg {
                    transform: rotate(-90deg);
                }
            }
        }

        & .actions {
            display: flex;
            justify-content: center;
            flex: 1 1;

            & > div {
                height: 30px;
                padding: 0px 10px;
                margin: 0px 5px;
                border: none;
                border-radius: 45px;
                font-size: 14px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                transition : transform 220ms ; 
                background-color: rgb(212, 212, 212);
                position: relative;

                &:hover {
                    transform : scale(1.05)
                }

                & > p {
                    color : rgb(73, 73, 73) ; 
                }

                &:hover > div {
                    display: flex;
                }

                & > div {
                    display: none; 
                    flex-direction: column;
                    position: absolute;
                    top : calc(100% + 10px) ;
                    border-radius : 10px ;  
                    background-color: #fafafa !important;
                    border: 1px solid #dadada;
                    box-shadow: 5px 5px 4px -2px rgba(0, 0, 0, .18);
                    width: 200px;
                    padding : 0px 5px ; 


                    & * {
                        color : $color_1
                    }

                    & p {

                        &:first-child {

                        }

                        &:last-child{
                            display: flex;
                            flex-direction: column;
                            & span {
                                font-style : italic
                            }
                        }
                    }
                }
            }

            &--pending > p, &--validated > p, &--refused > p {
                color : white!important
            }

            &--pending {
                background-color: #d68c03!important;
            }

            &--validated {
                background-color: #4e922a!important;
            }

            &--refused {
                background-color : #860c25!important ; 
            }
        }
    }

    &--container {
        width: 100%;
        height: calc(100% - 50px);
        display : flex ; 
    }

    & .preview {
        width: calc(100% - 400px);
        height: 100%;


        & iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    & .update {
        width: 400px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        & .categorizer-manager {
            padding: 10px 0px;
            width: 400px;
            height: auto ;
    
            &__header {
                width: 100%;
                flex-wrap: wrap;
                height: auto;
    
                & > div {
                    margin : 10px 10px
                }
            }
    
            &__container {
                display: flex;
                flex-direction: column;
                align-items: center;

                & .type {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    z-index: 2000;
    
                    & .dropdown {
                        width: 200px;
                    }
                }
    
                & > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 10px 0px;
                    & label {
                        @include labelStyle() ; 
                    }
    
                }
    
                & .id_supplier {
                    display: flex;
                    flex-direction: column;
        
                    &--supposed-supplier {
                        display: flex;
                        justify-content: flex-start;
                        margin: 0;
                    }
        
                    & > p {
                        font-size: 12px;
                        font-style: italic;
        
                        & span {
        
                            &:last-child {
                                cursor: pointer;
                                &:hover {
                                font-weight: bold;
                                text-decoration: underline;
        
                            }
                            }
                        }
                    }
                }
        
                & .inv_number, .due_date, .date, .receipt_date {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
        
        
                    & input {
                        width: 150px;
                        text-align: center;
                        @include inputStyle()
                    }
                }
        
                & .inv_number, .due_date {
                    & input {
                        text-align: center;
                    }
                }

                & .due_date {
                    & .inv_on_receipt {
                        $circle-checkbox-inv-on-receipt : 20px ;
                        position: relative;
                        display: flex;
                        align-items: center;
                        & .checkbox {
                            width: 40px;
                            height: calc($circle-checkbox-inv-on-receipt);
                            border-radius: 20px;
                            background-color: rgb(177, 177, 177);
                            display: flex;
                            align-items: center;
                            margin-right: 5px;
                            position: relative;
                            cursor: pointer;

                            & > div {
                                width: calc($circle-checkbox-inv-on-receipt - 5px);
                                height: calc($circle-checkbox-inv-on-receipt - 5px);
                                background-color: white;
                                border-radius: 50%;
                                transition : all 110ms ; 
                                left : 3px ; 
                                position: absolute;
                            }

                            &--true {
                                background-color: #2196f3;
                                & > div {
                                    left : calc(100% - $circle-checkbox-inv-on-receipt + 2px ); 

                                }
                            }
                        }
                    }
                }
        
                & .total_ttc, .doc_number {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
        
                    & div, input {
                        width: 80px;
                        text-align: center;
                        font-weight: 400;
                        color : white!important ; 
                        background-color: rgb(46, 46, 46);
                        letter-spacing: 0.5px;
                        font-size: 13px;
                        padding : 5px 10px ;
                        opacity: 1;
                        border-radius: 5px;
                        border: none!important;
                        
                        &:hover {
                            opacity: 0.8;
                        }
        
                        &:focus {
                            outline: none;
                        }
                    }
            
        
            
                }
        
                & .total_tva, .total_ht {
                    flex-direction: column;
        
                    & > div {
                        display: flex;
                        align-items: center;
                        & input {
                            width: 80px;
                            text-align: end;
                            @include inputStyle()
                        }
                        & p {
                            margin: 0;
                        }
                    }
        
                }
        
                & .search-select {
                    width: 200px;
                }
        
                & .comment {
                    display: flex;
                    flex-direction: column;
                    width: 300px;
                    
                    & textarea {
                        margin: 10px 10px;
                        display: flex;
                        width: calc(100% - 30px - 20px);
                        flex: 1 1;
                        padding: 10px 15px;
                        resize: none;
                        border-radius: 10px;
                        font-family: $font;
                        border: 1px solid rgb(0, 0, 0, 0.2);
                        min-height: 200px;
                        max-height: 300px;
                        
                        &:focus {
                            outline : none ; 
                            border : 1.5px solid $color_3
                        }
                    }
                }
            }
    
            
    
            
        }
    }
}