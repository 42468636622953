


.sales {

    & .stepsText {
        position : fixed
    }

    & .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & svg {
            width: 400px;
            margin-bottom: 20px;
        }

    }
}