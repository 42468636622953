.my-account {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;

    & > div {
        width: calc(350px - 20px - 2px);
        background-color: #fEFEFE;
        border-radius: 5px;
        border : 1px solid #E0E0E0;
        margin: 10px 10px;
        min-height: 200px;
        max-height: 100%;
        //-webkit-box-shadow: 0px 7px 9px -2px rgba(0,0,0,0.36); 
        //box-shadow: 0px 7px 9px -2px rgba(0, 0, 0, 0.11);  
        
        & h1 {
            width: calc(100%);
            height: calc(40px - 10px);
            background: $color_3;
            color: white;
            padding: 5px 0px;
            font-weight: 600;
            font-size: 15px;
            margin: 0;
            text-align: center;
            justify-content: center;
            display: flex;
            align-items: center;
        }

        & > div {
            height: calc(100% - 40px - 70px);
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-y: scroll;
            
            & > div {
                display: flex;
                flex-direction: column;
                width: calc(100% - 50px);
                margin: 10px 0px;

                & label {
                    @include labelStyle() ; 
                    text-align: justify!important
                }

                & input {
                    @include inputStyle() ; 
                }
            }

            & .main-button {
                width: 100px;
                margin: 20px 0px 10px 0px;
            }
        }
    }

}